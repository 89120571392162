import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RecintosService } from "../service/RecintosService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const recintoService = new RecintosService();

export const Recintos = () => {
    const title = "MATNELO - Recintos Asistenciales";
    let grupoVacio = {
        id: null,
        nombre: "",
    };

    const [recintos, setRecintos] = useState(null);
    const [recintoDialog, setRecintoDialog] = useState(false);
    const [recinto, setRecinto] = useState(grupoVacio);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        recintoService.getRecintos().then((data) => {
            setRecintos(data);
            setLoading(false);
        });
    }, []);

    const openNew = () => {
        setRecinto(grupoVacio);
        setSubmitted(false);
        setRecintoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecintoDialog(false);
    };

    const saveRecinto = async () => {
        setSubmitted(true);

        if (recinto.nombre.trim()) {
            let _recintos = [...recintos];
            let _recinto = { ...recinto };
            if (recinto.id) {
                const index = findIndexById(recinto.id);

                let res = await recintoService.updateRecinto(_recinto);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _recintos[index] = _recinto;
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    return;
                }
            } else {
                let res = await recintoService.recinto(_recinto);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _recinto = { ..._recinto, id: res.data.id };
                        _recintos.push(_recinto);
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    return;
                }
            }

            setRecintos(_recintos);
            setRecintoDialog(false);
            setRecinto(grupoVacio);
        }
    };

    const editRecinto = (recinto) => {
        setRecinto({ ...recinto });
        setRecintoDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < recintos.length; i++) {
            if (recintos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _recinto = { ...recinto };
        _recinto[`${name}`] = val;

        setRecinto(_recinto);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" tooltip="Nuevo Recinto" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.id}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                <Link to={`/recinto/${rowData.id}`}>{rowData.nombre}</Link>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Editar Recinto" className="p-button-rounded p-button-success p-mr-2" onClick={() => editRecinto(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Recintos Asistenciales</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let valido = recinto.nombre.length <= 100 ? false:true;

    const grupoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" disabled={valido} className="p-button-text" onClick={saveRecinto} />
        </>
    );

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                        {loading && (
                            <>
                                <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            </>
                        )}

                        <DataTable
                            ref={dt}
                            value={recintos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Recintos Asistenciales"
                            globalFilter={globalFilter}
                            header={header}
                            loading={loading}
                        >
                            <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                            <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                            <Column header="Editar" body={actionBodyTemplate}></Column>
                        </DataTable>

                        <Dialog visible={recintoDialog} style={{ width: "450px" }} header="Detalles Recinto" modal className="p-fluid" footer={grupoDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="nombre">Nombre</label>
                                <InputText id="nombre" value={recinto.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !recinto.nombre })} />
                                {submitted && !recinto.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                                {recinto.nombre.length >= 100 && <small className="p-invalid">El nombre del recinto no puede exceder los 100 caracteres.</small>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "../pages/Login";
import App from "../App";
import { Auth } from "../Auth";
import { UsuarioContext } from "../hooks/UsuarioContext";
import axios from "axios";

export const AppRouters = () => {
    const [usuario, setUsuario] = useState(null);
    axios.interceptors.request.use((config) => {
        if (usuario?.token) {
            const token = usuario.token;
            config.headers.Authorization = token;
        }

        return config;
    });

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                setUsuario(null);
            }
            return error;
        }
    );

    return (
        <UsuarioContext.Provider value={{ usuario, setUsuario }}>
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route path="/" component={(props) => <Auth {...props} Component={App} />} />
                </Switch>
            </Router>
        </UsuarioContext.Provider>
    );
};

import axios from "axios";

export class BodegasService {
    async getBodegas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/bodegas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async bodega(bodega) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/bodega`, bodega)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateBodega(bodega) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/bodega/${bodega.id}`, bodega)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

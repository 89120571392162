import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DevolucionRecintoService } from "../service/DevolucionRecintoService";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const devolucionRecintoservice = new DevolucionRecintoService();

export const DevolucionByRecinto = () => {
    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [farmacos, setFarmacos] = useState([]);
    const [farmaco, setFarmaco] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [contador, setContador] = useState(0);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [lote, setLote] = useState(null);
    const [lotes, setLotes] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        devolucionRecintoservice.detallesParaDevolucion({ despacho_id: id, recinto_id: usuario.data.recinto_id }).then((data) => {
            setFarmacos(data);
            setLoading(false);
        });
    }, [id, usuario.data.recinto_id]);

    const hideDialog = () => {
        setProductDialog(false);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
    };

    const saveSeleccion = () => {
        let _orders = farmaco.orders.filter((data) => data.cod_lote !== lote.cod_lote);
        let _lotes = farmaco.lotes.filter((data) => data.cod_lote !== lote.cod_lote);
        let _farmacos = farmacos.filter((data) => data.id !== farmaco.id);

        let _lote = { ...lote, seleccion: lote.seleccion + cantidad, stock: lote.stock - cantidad };
        let _farmaco = { ...farmaco, numero: farmaco.numero + cantidad, orders: [..._orders, _lote], lotes: [..._lotes, _lote] };
        setFarmacos([..._farmacos, _farmaco]);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote agregado correctamente`, life: 3000 });
        setLote(null);
        setFarmaco(null);
        hideCantidad();
        setCantidad(0);
        setSubmitted(false);
        setContador(contador + 1);
        hideDialog();
    };

    const seleccionarFarmaco = (rowData) => {
        setFarmaco(rowData);
        setLotes(rowData.lotes);
        setProductDialog(true);
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setCantidadDialog(true);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {lote?.stock - cantidad >= 0 && farmaco.numero + cantidad <= farmaco.cantidad && cantidad !== 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const actionBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.cantidad <= rowData.numero) {
            _respuesta = "Stock Completo";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco O Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    const eliminarLote = (rowData) => {
        let _farmaco = farmacos.find((data) => data.id === rowData.farmaco_id);
        let _lotes = _farmaco.lotes.filter((data) => data.cod_lote !== rowData.cod_lote);
        let _orders = _farmaco.orders.filter((data) => data.cod_lote !== rowData.cod_lote);

        let _lote = { ...rowData, stock: rowData.stock + rowData.seleccion, seleccion: 0 };
        let _farmacos = farmacos.filter((data) => data.id !== rowData.farmaco_id);

        let farmacoR = { ..._farmaco, numero: 0, orders: [..._orders], lotes: [..._lotes, _lote] };

        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote quitado correctamente`, life: 3000 });
        setFarmacos([..._farmacos, farmacoR]);
        setContador(contador - 1);
    };

    const eliminarBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={() => eliminarLote(rowData)} />
            </div>
        );
    };

    const seleccionarLoteBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.stock + rowData.seleccion <= rowData.seleccion) {
            _respuesta = "Sin Stock";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    if (redirect) {
        return <Redirect to='/' />
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _cantidad = cantidad;
        _cantidad = val;

        setCantidad(_cantidad);
    };

    const saveDevolucion = async () => {
        setSubmitted(true);
        setLoadingDialog(true);
        if (contador > 0) {
            let _data = [];
            farmacos.forEach((farmaco) => {
                if (farmaco.numero > 0) {
                    farmaco.orders.forEach((orden) => {
                        _data = [
                            ..._data,
                            {
                                cod_lote: orden.cod_lote,
                                seleccion: orden.seleccion,
                                farmaco_id: farmaco.id,
                            },
                        ];
                    });
                }
            });

            let datos = { despacho_id: id, recinto_id: usuario.data.recinto_id, usuario_id: usuario.data.sub, lotes: _data };

            let json = JSON.stringify(datos);
            let params = "json=" + json;

            let res = await devolucionRecintoservice.devolucionRecinto(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    window.open(`${process.env.REACT_APP_API_URL}/api/devolucionRecintoPDF/${res.data.cod_devolucion}`)
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `No ha seleccionado ningun lote para la devolución`, life: 3000 });
        }
        setSubmitted(false);
        setLoadingDialog(false);
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <>{_fecha.toLocaleDateString()}</>;
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Crear Devolución" tooltip="Crear Devolución" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={saveDevolucion} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.nombre, life: 3000 });
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => <span>{data.fecha_vencimiento}</span>}></Column>
                    <Column field="data.seleccion" header="Cantidad Seleccionada" sortable body={(data) => <span>{data.seleccion}</span>}></Column>
                    <Column header="Eliminar" body={eliminarBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    return (
        <div className="p-grid table-demo">
            <Helmet>
                <title>MATNELO - Devolución Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <h5>Detalles Devolución</h5>
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />
                    <DataTable loading={loading} value={farmacos} expandedRows={expandedRows} className="p-datatable-responsive" dataKey="id" onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}>
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="cantidad" header="Cantidad Por Devolver" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="numero" header="Cantidad Seleccionada" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column header="Seleccionar Fármaco o Insumo Médico" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header={`Lotes Disponibles de ${farmaco?.nombre}`} modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={lotes}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="stock" header="Cantidad" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="laboratorio" header="Laboratorio" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaVencimientoBodyTemplate} sortable></Column>
                            <Column header="Seleccionar Lote" body={seleccionarLoteBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <div>{lote && <small>Faltantes para completar la devolución: {farmaco.cantidad - farmaco.numero}</small>}</div>

                            <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && cantidad === 0 })} />
                            {lote && <small>Cantidad disponible del lote seleccionado: {lote.stock}</small>}
                            {submitted && cantidad === 0 && <small className="p-invalid">La cantidad es requerida.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

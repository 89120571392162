import axios from "axios";

export class RecintosService {
    async getRecintos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recintos`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async recinto(recinto) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/recinto`, recinto)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateRecinto(recinto) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/recinto/${recinto.id}`, recinto)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RecepcionesService } from "../service/RecepcionesService";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

const recepcionLotesServices = new RecepcionesService();

export const RecepcionLotes = () => {
    const { id } = useParams();
    const title = "Recepción";
    const [farmacos, setFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [recepcion, setRecepcion] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        recepcionLotesServices.getRecepcionLotes(id).then((data) => {
            setFarmacos(data.data);
            setRecepcion(data.data2);
            setLoading(false); 
        });
    }, [id]);

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha de vencimiento</span>
                {_fecha.toLocaleDateString()}
            </>
        );
    };

    const exportCSV = async () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/recepcionPDF/${recepcion.cod_recepcion}`)
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Generar Documento" tooltip="Documento" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                        {recepcion && (
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <div className="card p-fluid">
                                        <h5>Datos Recepción</h5>
                                        <div className="p-field">
                                            <label htmlFor="nombre">Recepcionado por: </label>
                                            <InputText id="nombre" value={`${recepcion.nombre} ${recepcion.apellido_paterno}`} disabled type="text" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="fecha">Fecha Recepción </label>
                                            <InputText id="fecha" value={`${recepcion.fecha}`} disabled type="text" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="factura">Numero Factura </label>
                                            <InputText id="factura" value={`${recepcion.numero_factura}`} disabled type="text" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="cod_recepcion">Código De Recepción </label>
                                            <InputText id="cod_recepcion" value={`${recepcion.cod_recepcion}`} disabled type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {loading && (
                            <>
                                <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            </>
                        )}
                        <DataTable
                            ref={dt}
                            value={farmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="cod_lote" header="Código Lote" ></Column>
                            <Column field="nombre" header="Nombre Fármaco / Insumo Médico" sortable ></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable ></Column>
                            <Column field="dosificacion" header="Dosificación" sortable ></Column>
                            <Column field="cantidad" header="Cantidad" sortable ></Column>
                            <Column field="laboratorio" header="Laboratorio" sortable></Column>
                            <Column field="precio" header="Precio" sortable ></Column>
                            <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaBodyTemplate} sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

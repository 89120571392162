import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { RecintosFarmacosService } from "../service/RecintosFarmacosService";
import { RadioButton } from "primereact/radiobutton";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const recintoFarmacos = new RecintosFarmacosService();

export const Recinto = ({ farmacos }) => {
    const { id } = useParams();
    let farmacoVacio = {
        id: null,
        cantidad: null,
        stock: null,
        activo: null,
        recinto_id: parseInt(id),
        farmaco_id: null,
        farmaco: {
            id: null,
            nombre: "",
            observacion: "",
            dosificacion: "",
            cantidad: null,
            stock_critico: null,
            bodega_id: null,
            grupo_id: null,
            forma_farmaceutica_id: null,
            forma_farmaceutica: {
                id: null,
                nombre: "",
            },
        },
    };

    const [newFarmacos, setNewFarmacos] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [farmaco, setFarmaco] = useState(farmacoVacio);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [reFarmacos, setReFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [title, setTitle] = useState('Recintos')
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [recinto, setRecinto] = useState({ nombre: "" });
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        recintoFarmacos.getRecintosFarmacos(id).then((data) => {
            setReFarmacos(data[0]);
            setRecinto(data[1]);
            setLoading(false);
            setTitle(data[1].nombre);
        });
    }, [id]);

    useEffect(() => {
        sacarExistentes();
    }, [reFarmacos]);

    const sacarExistentes = async () => {
        let _farmacos = [...farmacos];
        await reFarmacos.forEach((data1) => {
            let array = _farmacos.filter((data2) => data2.id !== data1.farmaco_id);
            _farmacos = [...array];
        });
        setNewFarmacos(_farmacos);
    };

    const openNew = () => {
        setFarmaco(farmacoVacio);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const editProduct = (farmaco) => {
        setFarmaco(farmaco);
        setEditDialog(true);
        //setFarmaco({ ...farmaco });
        /* setProductDialog(true); */
    };


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };


    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.activo === 1) {
            status = {
                status: "instock",
                message: "Activado",
            };
        } else {
            status = {
                status: "outofstock",
                message: "Desactivado",
            };
        }
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    };

    const nombre2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const dosificacion2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dosificación</span>
                {rowData.dosificacion}
            </>
        );
    };

    const grupo2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Grupo</span>
                {rowData.grupo.nombre}
            </>
        );
    };

    const formaFarmeceutica2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Forma Farmaceutica</span>
                {rowData.forma_farmaceutica.nombre}
            </>
        );
    };

    const action2BodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        let _farmaco = { ...farmaco };
        _farmaco = {
            ...farmaco,
            activo: 1,
            farmaco_id: rowData.id,
            farmaco: {
                id: rowData.id,
                nombre: rowData.nombre,
                observacion: rowData.nombre,
                dosificacion: rowData.dosificacion,
                cantidad: rowData.cantidad,
                stock_critico: rowData.stock_critico,
                bodega_id: rowData.bodega_id,
                grupo_id: rowData.grupo_id,
                forma_farmaceutica_id: rowData.forma_farmaceutica_id,
                forma_farmaceutica: {
                    id: rowData.forma_farmaceutica.id,
                    nombre: rowData.forma_farmaceutica.nombre,
                },
            },
        };
        setFarmaco(_farmaco);
        setCantidadDialog(true);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
    };

    const hideEdit = () => {
        setEditDialog(false);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < reFarmacos.length; i++) {
            if (reFarmacos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const saveSeleccion = async () => {
        setLoadingDialog(true);
        let _farmacos = [...reFarmacos];
        if (farmaco.id) {
            const index = findIndexById(farmaco.id);
            let _farmaco = {
                id: farmaco.id,
                cantidad: farmaco.cantidad,
                stock: farmaco.stock,
                activo: farmaco.activo,
                recinto_id: farmaco.recinto_id,
                farmaco_id: farmaco.farmaco_id,
            };

            let res = await recintoFarmacos.updateRecintoFarmaco(_farmaco);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    _farmacos[index] = farmaco;
                    setLoadingDialog(false);
                } else {
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    setLoadingDialog(false);
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            let _farmaco = {
                cantidad: farmaco.cantidad,
                stock: farmaco.stock,
                activo: farmaco.activo,
                recinto_id: farmaco.recinto_id,
                farmaco_id: farmaco.farmaco_id,
            };

            let res = await recintoFarmacos.recintoFarmaco(_farmaco);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    _farmaco = { ...farmaco, id: res.data.id };
                    _farmacos = [...reFarmacos, _farmaco];
                    setLoadingDialog(false);
                } else {
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    setLoadingDialog(false);
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        }
        hideEdit();
        setReFarmacos(_farmacos);
        hideCantidad();
        setProductDialog(false);
        setFarmaco(farmacoVacio);
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e insumos Médicos {recinto.nombre}</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let validacion = farmaco.cantidad > 0 ? false : true;

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            <Button label="Guardar" icon="pi pi-check" disabled={validacion} className="p-button-text" onClick={saveSeleccion} />
        </>
    );

    const editDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideEdit} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />
        </>
    );

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _farmaco = { ...farmaco };
        _farmaco[`${name}`] = val;

        setFarmaco(_farmaco);
    };

    const onCategoryChange = (e) => {
        let _farmaco = { ...farmaco };
        _farmaco["activo"] = e.value;
        setFarmaco(_farmaco);
    };



    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                        {loading && (
                            <>
                                <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            </>
                        )}
                        <DataTable
                            ref={dt}
                            value={reFarmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="farmaco.nombre" header="Nombre" sortable ></Column>
                            <Column field="farmaco.dosificacion" header="Dosificación" sortable ></Column>
                            <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                            <Column field="estado" header="Estado" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                        <DialogProcesando visible={loadingDialog} />

                        <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Fármaco O Insumo Médico" modal className="p-fluid" onHide={hideDialog}>
                            <DataTable
                                ref={dt}
                                value={newFarmacos}
                                dataKey="id"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                                globalFilter={globalFilter2}
                                header={header2}
                            >
                                <Column field="nombre" header="Nombre" sortable body={nombre2BodyTemplate}></Column>
                                <Column field="dosificacion" header="Dosificación" sortable body={dosificacion2BodyTemplate}></Column>
                                <Column field="grupo.nombre" header="Grupo" sortable body={grupo2BodyTemplate}></Column>
                                <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceutica2BodyTemplate}></Column>
                                <Column header="Seleccionar" body={action2BodyTemplate}></Column>
                            </DataTable>
                        </Dialog>

                        <Dialog visible={editDialog} style={{ width: "700px" }} header="Detalles Fármacos e Insumos Médicos" modal className="p-fluid" footer={editDialogFooter} onHide={hideEdit}>
                            <div className="p-field">
                                <label htmlFor="cantidad">Cantidad establecida</label>
                                <InputNumber id="cantidad" value={farmaco.cantidad} onChange={(e) => onInputNumberChange(e, "cantidad")} required autoFocus className={classNames({ "p-invalid": submitted && !farmaco.cantidad })} />
                                {submitted && !farmaco.cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                            </div>
                            <div className="p-field">
                                <label className="p-mb-3">Estado</label>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton inputId="category1" name="activo" value={1} onChange={onCategoryChange} checked={farmaco.activo === 1} />
                                        <label htmlFor="category1">Activado</label>
                                    </div>
                                    <div className="p-field-radiobutton p-col-6">
                                        <RadioButton inputId="category2" name="activo" value={0} onChange={onCategoryChange} checked={farmaco.activo === 0} />
                                        <label htmlFor="category2">Desactivado</label>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalle Fármaco o Insumo Médico " modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                            <div className="p-field">
                                <label htmlFor="cantidad">Cantidad establecida</label>
                                <InputNumber id="cantidad" value={farmaco.cantidad} onChange={(e) => onInputNumberChange(e, "cantidad")} required autoFocus className={classNames({ "p-invalid": submitted && !farmaco.cantidad })} />
                                {submitted && !farmaco.cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { Redirect, useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { DespachoRecintoService } from "../service/DespachoRecintoService";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";


const despachoRecintoService = new DespachoRecintoService();

export const DespachoRecinto = () => {
    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [farmacos, setFarmacos] = useState(null);
    const [farmaco, setFarmaco] = useState(null);
    const [lote, setLote] = useState(null);
    const [lotesFarmaco, setLotesFarmaco] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [contador, setContador] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {

        despachoRecintoService.obtenerFarmacosSolicitud({ solicitud_id: id, recinto_id: usuario.data.recinto_id }).then((data) => {
            setFarmacos(data.data)
            setLoading2(false);
        });
    }, [id, usuario.data.recinto_id]);

    if (redirect) {
        return <Redirect to='/' />
    }



    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        let _cantidad = cantidad;
        _cantidad = val;

        setCantidad(_cantidad);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.nombre, life: 3000 });
    };


    const hideDialog = () => {
        setProductDialog(false);
    };

    const codLoteBodyTemplate = (rowData) => {
        return <>{rowData.cod_lote}</>;
    };

    const stockBodyTemplate = (rowData) => {
        return <>{rowData.stock}</>;
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <>
                {_fecha.toLocaleDateString()}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.cantidad <= rowData.numero) {
            _respuesta = "Stock Completo";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    const seleccionarBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Agregar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        setFarmaco(rowData)
        setLotesFarmaco(rowData.lotes);
        setLoading(false);
        setProductDialog(true);
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setCantidadDialog(true);
    };


    const saveSeleccion = () => {
        setContador(contador + 1);
        let _lote = { ...lote, seleccion: lote.seleccion + cantidad, stock: lote.stock - cantidad };
        let _lotes = farmaco.lotes.filter(data => data.cod_lote !== lote.cod_lote);
        let _orders = farmaco.orders.filter(data => data.cod_lote !== lote.cod_lote);
        let _farmaco = { ...farmaco, numero: farmaco.numero + cantidad, lotes: [..._lotes, _lote], orders: [..._orders, _lote] };

        let _farmacos = farmacos.filter(data => data.id !== farmaco.id);

        setFarmacos([..._farmacos, _farmaco])
        hideCantidad()
        hideDialog()
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const saveDespacho = async () => {
        setLoadingDialog(true);
        setSubmitted(true);
        if (contador !== 0) {

            let _orders = [];
            farmacos.forEach(farmaco => {
                farmaco.orders.forEach(order => {
                    _orders = [..._orders, { farmaco_id: farmaco.id, cod_lote: order.cod_lote, seleccion: order.seleccion }]
                })
            })

            let _data = {
                usuario_id: usuario.data.sub,
                recinto_id: usuario.data.recinto_id,
                solicitud_id: id,
                lotes: _orders
            }

            let json = JSON.stringify(_data);
            let params = "json=" + json;


            let res = await despachoRecintoService.despachoRecinto(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    window.open(`${process.env.REACT_APP_API_URL}/api/despachoRecintoPDF/${res.data.cod_despacho}`)
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }

                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });

            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `No ha seleccionado ningun lote de fármaco e insumo médico para despacho`, life: 3000 });

        }
        setLoadingDialog(false);
        setSubmitted(false);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Crear despacho" tooltip="Crear Despacho" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={saveDespacho} />
            </React.Fragment>
        );
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setCantidad(0);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {lote?.stock - cantidad >= 0 && farmaco.numero + cantidad <= farmaco.cantidad && cantidad !== 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => <span>{data.fecha_vencimiento}</span>}></Column>
                    <Column field="data.seleccion" header="Cantidad Seleccionada" sortable body={(data) => <span>{data.seleccion}</span>}></Column>
                </DataTable>
            </div>
        );
    };

    return (
        <div className="p-grid table-demo">
            <Helmet>
                <title>MATNELO - Despacho Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <h5>Detalles de despacho</h5>
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />
                    <DataTable loading={loading2} value={farmacos} expandedRows={expandedRows} className="p-datatable-responsive" dataKey="id" onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}>
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="cantidad" header="Cantidad Solicitada" sortable ></Column>
                        <Column field="dosificacion" header="Dosificación" sortable ></Column>
                        <Column field="numero" header="Cantidad Seleccionada" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar lotes disponibles" modal className="p-fluid" onHide={hideDialog}>
                <DataTable
                    ref={dt}
                    value={lotesFarmaco}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >
                    <Column field="cod_lote" header="Código" body={codLoteBodyTemplate}></Column>
                    <Column field="stock" header="Stock Disponible" body={stockBodyTemplate}></Column>
                    <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaVencimientoBodyTemplate}></Column>
                    <Column header="Seleccionar" body={seleccionarBodyTemplate}></Column>
                </DataTable>
            </Dialog>

            <DialogProcesando visible={loadingDialog} />

            <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                <div className="p-field">
                    <label htmlFor="cantidad">Cantidad</label>
                    <div>{lote && <small>Faltantes para completar la solicitud: {farmaco.cantidad - farmaco.numero}</small>}</div>

                    <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && cantidad === 0 })} />
                    {lote && <small>Cantidad disponible del lote seleccionado: {lote.stock}</small>}
                    {submitted && cantidad === 0 && <small className="p-invalid">La cantidad es requerida.</small>}
                </div>
            </Dialog>
        </div>
    );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SolicitudesRecintosService } from "../service/SolicitudesRecintosService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Helmet } from "react-helmet";

const solicitudesRecintosService = new SolicitudesRecintosService();

export const SolicitudesEntreRecintos = () => {
    const { usuario } = useContext(UsuarioContext);

    const [solicitudes, setSolicitudes] = useState([]);
    const [detalles, setDetalles] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [solicitud, setSolicitud] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        solicitudesRecintosService.getSolicitudesRecinto(usuario.data.recinto_id).then((data) => {
            setSolicitudes(data);
            setLoading(false);
        });
    }, [usuario.data.recinto_id]);

    const verDetalles = (rowData) => {
        setProductDialog(true);
        setSolicitud(rowData);
        solicitudesRecintosService.getDetallesSolicitudRecinto(rowData.id).then((data) => {
            setDetalles(data);
            setLoading2(false);
        });
    };

    const estadoBodyTemplate = (rowData) => {
        let status;
        if (rowData.respuesta === 0) {
            status = {
                status: "outofstock",
                message: "Sin respuesta",
            };
        } else {
            status = {
                status: "instock",
                message: "Con respuesta",
            };
        }
        return (
            <>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" tooltip="Detalles Solicitud" className="p-button-rounded p-button-info p-mr-2" onClick={() => verDetalles(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Solicitudes para recintos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <h5 className="p-m-0">Detalles solicitud</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de despacho</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const exportCSV = async () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/solicitudRecintoPDF/${solicitud.cod_solicitud}`)
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Generar Documento" tooltip="Documento" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const hideDialog = () => {
        setProductDialog(false);
        setDetalles([]);
    };

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Solicitud Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={solicitudes}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Solicitudes"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="cod_solicitud" header="Código" sortable></Column>
                        <Column field="nombre" header="Usuario" sortable></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="respuesta" header="Estado" sortable body={estadoBodyTemplate}></Column>
                        <Column header="Detalles" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header={`Detalles solicitud ${solicitud?.cod_solicitud}`} modal className="p-fluid" onHide={hideDialog}>
                        {loading2 && <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />}
                        <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            ref={dt}
                            value={detalles}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                            globalFilter={globalFilter2}
                            header={header2}
                            loading={loading2}
                        >
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="cantidad" header="Cantidad solicitada" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="grupo" header="Grupo" sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";

export class FormasFarmaceuitcasService {
    async getFormasFarmaceuticas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/formas_farmaceuticas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async formaFarmaceutica(formaFarmaceutica) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/forma_farmaceutica`, formaFarmaceutica)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateFormaFarmaceutica(formaFarmaceutica) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/forma_farmaceutica/${formaFarmaceutica.id}`, formaFarmaceutica)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { LotesService } from "../service/LotesService";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { BajasServices } from "../service/BajasService";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Helmet } from "react-helmet";

const bajasServices = new BajasServices();
const lotesServices = new LotesService();

export const Bajas = () => {
    const { usuario } = useContext(UsuarioContext);
    const [bajas, setBajas] = useState([]);
    const [baja, setBaja] = useState(null);
    const [lotes, setLotes] = useState([]);
    const [loading2, setLoading2] = useState(true);
    const [confirmacionDialog, setConfirmacionDialog] = useState(false);
    const [loteDialog, setLoteDialog] = useState(false);
    const [detalleDialog, setDetalleDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cantidad, setCantidad] = useState(0);
    const [motivo, setMotivo] = useState("");
    const [subbmited, setSubbmited] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        bajasServices.getBajas().then((data) => {
            setBajas(data);
            setLoading(false);
        });
    }, []);

    const hideDialog = () => {
        setConfirmacionDialog(false);
    };

    const hideLoteDialog = () => {
        setLoteDialog(false);
    };

    const hideDetalleDialog = () => {
        setDetalleDialog(false);
        setSubbmited(false);
    };

    const nuevaBaja = () => {
        setLoteDialog(true);
        lotesServices.getLotesDisponibles().then((data) => {
            setLotes(data);
            setLoading2(false);
        });
    };

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const onInputChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setMotivo(val);
    };

    const saveBaja = async () => {
        setSubbmited(true);
        if (cantidad === 0 || motivo.length <= 0 || motivo.length > 100) {
            toast.current.show({ severity: "error", summary: "Error", detail: `Debe completar el campo de Motivo y el campo de Cantidad`, life: 3000 });
            hideDialog();
            return;
        } else {
            let _baja = {
                id: null,
                baja_cod: "",
                motivo: motivo,
                fecha: null,
                cantidad: cantidad,
                usuario_id: usuario.data.sub,
                cod_lote: baja.cod_lote,
            };
            let date = new Date();
            let _bajas = [...bajas];
            let res = await lotesServices.bajarVencidosBodegas(_baja);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    let _baja = { ...res.data, ...baja, fecha: date, usuario: usuario.data.nombre };
                    _bajas.push(_baja);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                return;
            }

            setMotivo("");
            setCantidad(0);
            setBajas(_bajas);
            setBaja(null);
            hideDialog();
            hideDetalleDialog();
            hideLoteDialog();
            setSubbmited(false);
        }
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    const fechaBajaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Bajas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const bodegaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveBaja} />
        </>
    );

    const detalleDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDetalleDialog} />
            {cantidad <= baja?.stock && cantidad > 0 && motivo.length <= 100 && motivo.length > 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={() => setConfirmacionDialog(true)} />}
        </>
    );

    const seleccionarFarmaco = (rowData) => {
        setBaja(rowData);
        setDetalleDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return <div className="actions">{rowData.stock > 0 && <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />}</div>;
    };

    const leftToolbarTemplate = () => {
        return <Button label="Nueva Baja" tooltip="Registrar nueva Baja" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={nuevaBaja} />;
    };

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Bajas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={bajas}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Bajas de lotes"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="baja_cod" header="Código Baja" sortable></Column>
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="usuario" header="Nombre Usuario" sortable></Column>
                        <Column field="dosificacion" sortable header="Dosificación"></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column field="fecha" header="Fecha de baja" body={fechaBajaBodyTemplate} sortable></Column>
                        <Column field="motivo" header="Motivo" sortable></Column>
                    </DataTable>

                    <Dialog visible={loteDialog} style={{ width: "1000px" }} header="Seleccionar Lote para baja" modal onHide={hideLoteDialog}>
                        <DataTable
                            ref={dt}
                            value={lotes}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter2}
                            header={header2}
                            loading={loading2}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="dosificacion" sortable header="Dosificación"></Column>
                            <Column field="forma_farmaceutica" sortable header="Forma Farmacéutica"></Column>
                            <Column field="stock" header="Stock Disponible" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha Vencimiento" sortable body={fechaVencimientoBodyTemplate}></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={detalleDialog} style={{ width: "600px" }} header="Detalles Baja de Lote" modal className="p-fluid" footer={detalleDialogFooter} onHide={hideDetalleDialog}>
                        <div className="p-field">
                            <label htmlFor="dosificacion">Motivo</label>
                            <InputTextarea id="dosificacion" value={motivo} onChange={(e) => onInputChange(e)} required autoFocus />
                            {motivo && motivo.length > 100 && <small className="p-invalid">El motivo no debe exceder los 100 caracteres.</small>}
                            {subbmited && !motivo.length && <small className="p-invalid">El motivo es requerido.</small>}
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cantidad">Cantidad</label>
                                <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} integeronly />
                                {cantidad > baja?.stock && <small className="p-invalid">La cantidad no puede exceder el stock actual de {baja.stock}.</small>}
                                {subbmited && cantidad === 0 && <small className="p-invalid">La cantidad es requerida</small>}
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={confirmacionDialog} style={{ width: "450px" }} header="Confirmar" modal footer={bodegaDialogFooter} onHide={hideDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {baja && (
                                <span>
                                    {" "}
                                    ¿Seguro que quiere bajar el lote con código: <b>{baja.cod_lote} </b> con una cantidad de: <b>{cantidad} </b> de los registros?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { LotesService } from "../service/LotesService";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const lotesService = new LotesService();

export const Vencidos = () => {
    const { usuario } = useContext(UsuarioContext);
    const [vencidos, setVencidos] = useState([]);
    const [vencido, setVencido] = useState(null);
    const [bodegaDialog, setBodegaDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        lotesService.getVencidosBodegas().then((data) => {
            setVencidos(data);
            setLoading(false);
        });
    }, []);

    const hideDialog = () => {
        setBodegaDialog(false);
    };

    const saveBaja = async () => {
        setLoadingDialog(true);

        let _baja = {
            id: null,
            baja_cod: "",
            motivo: "vencidos",
            fecha: null,
            cantidad: vencido.stock,
            usuario_id: usuario.data.sub,
            cod_lote: vencido.cod_lote,
        };

        let res = await lotesService.bajarVencidosBodegas(_baja);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Successful", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }

        let _vencidos = vencidos.filter((data) => data.id !== vencido.id);
        setLoadingDialog(false);
        setVencidos(_vencidos);
        setVencido(null);
        hideDialog();
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => {
                        setBodegaDialog(true);
                        setVencido(rowData);
                    }}
                />
            </div>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <span>
                {_fecha.toLocaleDateString()}
            </span>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Lotes Vencidos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const bodegaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveBaja} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Vencidos</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={vencidos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes Vencidos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="nombre" header="Nombre"></Column>
                        <Column field="dosificacion" header="Dosificacion"></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmaceutica"></Column>
                        <Column field="stock" header="Cantidad" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaBodyTemplate}></Column>
                        <Column header="Bajar Vencidos" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={bodegaDialog} style={{ width: "450px" }} header="Confirmar" modal footer={bodegaDialogFooter} onHide={hideDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {vencido && (
                                <span>
                                    {" "}
                                    ¿Seguro que quiere sacar el lote con código: <b>{vencido.cod_lote} </b> de los registros?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

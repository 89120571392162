import axios from "axios";

export class SolicitudesService {
    async getSolicitudes() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/solicitudes`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }
    

    async getSolicitudesNuevas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSolicitudesNuevas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getSolicitudesById(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSolicitudesById/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getSolicitudesByRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSolicitudesByRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getFarmacosSolicitud(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getFarmacosSolicitud/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async rechazarSolicitud(id) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/rechazarSolicitud/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateSolicitud(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/updateSolicitud`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async solicitud(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/solicitud`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

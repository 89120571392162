import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { DespachoService } from "../service/DespachoService";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SalidasService } from "../service/SalidasService";
import { SolicitudesService } from "../service/SolicitudesService";

const despachosService = new DespachoService();
const salidasService = new SalidasService();
const solicitudesServices = new SolicitudesService();

export const Confirmacion = () => {
    const { id } = useParams();
    const { usuario } = useContext(UsuarioContext);
    const [detalles, setDetalles] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [lote, setLote] = useState({ recepcionado: 0 });
    const [expandedRows, setExpandedRows] = useState([]);
    const [despacho, setDespacho] = useState(null);
    const [loading2, setLoading2] = useState(true);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [farmacos, setFarmacos] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachosService.getDetallesDespacho(id).then((data) => {
            let _detalle = data[1].map((lot) => {
                return { ...lot, recepcionado: lot.cantidad };
            });
            solicitudesServices.getFarmacosSolicitud(data[0][0].solicitud.id).then((dat) => {
                let _data = dat[0].map((farmaco) => {
                    let _order = [];
                    let cantidad = 0;
                    data[1].forEach((lot) => {
                        if (lot.farmaco_id === farmaco.farmaco_id) {
                            _order = [..._order, { ...lot, recepcionado: lot.cantidad }];
                            cantidad = cantidad + lot.cantidad;
                        }
                    });
                    return {
                        ...farmaco,
                        cantidad: cantidad,
                        orders: [..._order],
                    };
                });
                setFarmacos(_data);
                setLoading2(false);
            });
            setDetalles(_detalle);
            setDespacho(data[0][0]);
            setLoading2(false);
        });
    }, [id]);

    if (redirect) {
        return <Redirect to={`/`} />;
    }

    const header2 = (
        <div className="table-header">
            <h5 className="p-m-0">Lotes registrados en el despacho</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const seleccionarFarmaco = (rowData) => {
        setLote(rowData);
        setProductDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const hideDialog = () => {
        setProductDialog(false);
        setLote({ recepcionado: 0 });
    };

    const cambiarCantidadRecepcionado = () => {
        detalles.forEach((data) => {
            if (data.cod_lote === lote.cod_lote) {
                data.recepcionado = lote.recepcionado;
            }
        });
        let _data = farmacos.map((farmaco) => {
            let _order = [];
            let cantidad = 0;
            detalles.forEach((lot) => {
                if (lot.farmaco_id === farmaco.farmaco_id) {
                    if (lot.cod_lote === lote.cod_lote) {
                        _order = [..._order, { ...lot, recepcionado: lote.recepcionado }];
                        cantidad = cantidad + lot.recepcionado;
                    } else {
                        _order = [..._order, { ...lot }];
                        cantidad = cantidad + lot.recepcionado;
                    }
                }
            });
            return {
                ...farmaco,
                cantidad: cantidad,
                orders: [..._order],
            };
        });
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Cantidad cambiada correctamente`, life: 3000 });
        setFarmacos(_data);
        setProductDialog(false);
    };

    let valido = lote.cantidad < lote.recepcionado ? false : true;

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {valido && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={cambiarCantidadRecepcionado} />}
        </>
    );

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _farmaco = { ...lote };
        _farmaco[`${name}`] = val;

        setLote(_farmaco);
    };

    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.cantidad === rowData.recepcionado) {
            status = {
                status: "instock",
                message: rowData.recepcionado,
            };
        } else {
            status = {
                status: "outofstock",
                message: rowData.recepcionado,
            };
        }
        return (
            <>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const confirmarRecepcion = async () => {
        let _lotes = detalles.filter((data) => data.cantidad !== data.recepcionado);

        let array = _lotes.map((data) => {
            return {
                cantidad: data.cantidad - data.recepcionado,
                motivo: `Faltantes en despacho ${despacho.despacho_cod}`,
                cod_lote: data.cod_lote,
                farmaco_id: data.farmaco_id,
            };
        });

        let _data = {
            usuario_id: usuario.data.sub,
            recinto_id: usuario.data.recinto_id,
            lotes: array,
        };

        let json = JSON.stringify(_data);
        let params = "json=" + json;

        const despachoServices = new DespachoService();
        let res = await despachoServices.confirmarDespacho(id);

        if (res?.status) {
            if (res.status === "success") {
                if (_lotes.length) {
                    let res2 = await salidasService.salida(params);

                    if (res2?.status) {
                        if (res2.status === "success") {
                            toast.current.show({ severity: "success", summary: "Éxito", detail: `Despacho confirmado con nuevas cantidades`, life: 3000 });
                            setTimeout(() => {
                                setRedirect(true);
                            }, 1000);
                        } else {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: `${res2.message}`, life: 3000 });
                            let errors = { ...res2.errors };
                            for (const property in errors) {
                                toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                            }
                            return;
                        }
                    }
                } else {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `Despacho confirmado correctamente`, life: 3000 });
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                }
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            return;
        }
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.farmaco.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.farmaco.nombre, life: 3000 });
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.farmaco.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => <span>{new Date(data.fecha_vencimiento).toLocaleDateString()}</span>}></Column>
                    <Column field="data.cantidad" header="Cantidad Despachada" sortable body={(data) => <span>{data.cantidad}</span>}></Column>
                    <Column field="data.cantidad" header="Cantidad Recepcionada" body={statusBodyTemplate} sortable></Column>
                    <Column header="Cambiar cantidad recepcionada" body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Confirmar despacho" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={confirmarRecepcion} />
            </React.Fragment>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Confirmación</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading2 ? (
                        <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                    ) : (
                        <>
                            <div className="p-col-12">
                                <div className="card">
                                    <h5>Despacho</h5>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="firstname2">Código Despacho</label>
                                            <InputText value={despacho.despacho_cod} disabled id="firstname2" type="text" />
                                        </div>
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="firstname2">Persona que Despacho</label>
                                            <InputText value={despacho.usuario.nombre} disabled id="firstname2" type="text" />
                                        </div>
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="firstname2">Código Solicitud</label>
                                            <InputText value={despacho.solicitud.cod_solicitud} disabled id="firstname2" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Toolbar className="p-mb-4" right={leftToolbarTemplate}></Toolbar>
                        </>
                    )}
                    
                    <DataTable
                        globalFilter={globalFilter2}
                        header={header2}
                        loading={loading2}
                        value={farmacos}
                        expandedRows={expandedRows}
                        className="p-datatable-responsive"
                        dataKey="id"
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand}
                        onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate}
                        ref={dt}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes de fármacos e insumos médicos"
                    >
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                        <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                        <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                        <Column field="cantidad" header="Cantidad Total Despachada" sortable></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "600px" }} header={`Cantidad recepcionada del lote ${lote?.cod_lote}`} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="stock">Cantidad recepcionada</label>
                                <InputNumber id="stock" value={lote.recepcionado} onChange={(e) => onInputNumberChange(e, "recepcionado")} integeronly />
                                {lote.cantidad < lote.recepcionado && <small className="p-invalid">La cantidad recepcionada no puede exceder lo despachado.</small>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

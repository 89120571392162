import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { DialogProcesando } from "../../components/DialogProcesando";
import { SolicitudesService } from "../../service/SolicitudesService";

const solicitudesService = new SolicitudesService();

export const ModificarSolicitud = () => {
    const { id } = useParams();
    const [farmacos, setFarmacos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [farmaco, setFarmaco] = useState(null);
    const [cantidad, setCantidad] = useState(0);
    const [dialog, setDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        solicitudesService.getFarmacosSolicitud(id).then((data) => {
            console.log(data[0]);
            setFarmacos(data[0]);
            setLoading(false);
        });
    }, [id]);

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const hideDialog = (rowData) => {
        setFarmaco(rowData);
        setCantidad(rowData.cantidad);
        setDialog(true);
    };

    const hideCantidad = () => {
        setDialog(false);
        setCantidad(0);
    };

    const saveCantidad = async () => {
        setLoadingDialog(true);
        let res = await solicitudesService.updateSolicitud({ farmaco_id: farmaco.farmaco.id, solicitud_id: id, cantidad: cantidad });

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
                farmacos.map((data) => {
                    if (data.farmaco.id === farmaco.farmaco.id) {
                        data.cantidad = cantidad;
                    }
                    return data;
                });
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }

        setLoadingDialog(false);
        setDialog(false);
        setCantidad(0);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveCantidad} />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Modificar cantidad" className="p-button-rounded p-button-warning p-mr-2" onClick={() => hideDialog(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Modificar Solicitud Bodegas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={farmacos}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        loading={loading}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter}
                        header={header}
                    >
                        <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                        <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                        <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column header="Modificar" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={dialog} style={{ width: "700px" }} header="Cantidad a solicitar" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" value={farmaco?.cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

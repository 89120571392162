import React from 'react'
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

export const ConfrimaciondevolucionRecintoRecinto = () => {
    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Tutorial Confirmación Devolución Desde Recinto Hacía Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <ReactPlayer 
                        url="assets/videos/Confirmacion devolucion recinto recinto.mp4"
                        width="100%"
                        height="100%"
                        controls
                    />
                </div>
            </div>
        </div>
    );
}

import axios from "axios";

export class PrestamosIntService {
   
    async prestamosInt(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/prestamo_int`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getPrestamosInt() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/prestamos_int`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getPrestamoInt(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/get_prestamo_int/${id}`,)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

   
}
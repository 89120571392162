import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DespachoRecintoService } from "../service/DespachoRecintoService";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { Toolbar } from "primereact/toolbar";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const despachoRecintoService = new DespachoRecintoService();

export const DespachosRecintoByRecinto = () => {
    const { usuario } = useContext(UsuarioContext);
    const [farmacos, setFarmacos] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [detalles, setDetalles] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [detalle, setDetalle] = useState("");
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachoRecintoService.getDespachosByRecinto(usuario.data.recinto_id).then((data) => {
            setFarmacos(data);
            setLoading(false);
        });
    }, [usuario.data.recinto_id]);

    const verDetalles = (devolucionInt) => {
        setDetalle(devolucionInt);
        setLoading2(true);
        setProductDialog(true);
        despachoRecintoService.getDetallesDespachoByRecinto(devolucionInt.id).then((data) => {
            setDetalles(data);
            setLoading2(false);
        });
    };

    const devolucion = (devolucionInt) => {
        setDetalle(devolucionInt);
        setRedirect(true);
    };

    if (redirect) {
        return <Redirect to={`/devolucionByRecinto/${detalle.id}`} />;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" tooltip="Detalles Despacho" className="p-button-rounded p-button-info p-mr-2" onClick={() => verDetalles(rowData)} />
                <Button icon="pi pi-plus" tooltip="Nueva Devolución" className="p-button-rounded p-button-warning p-mr-2" onClick={() => devolucion(rowData)} />
            </div>
        );
    };

    const confirmarDespacho = async () => {
        let res = await despachoRecintoService.confirmarDespachoRecinto(detalle.id);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                let _despachos = farmacos.filter((data) => data.id !== detalle.id);
                setFarmacos([..._despachos, { ...detalle, confirmado: 1 }]);
                hideDialog();
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            return;
        }
    };

    const exportCSV = async () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/despachoRecintoPDF/${detalle.cod_despacho}`);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {detalle.confirmado !== 1 && usuario.data?.recinto && <Button label="Confirmar Despacho" tooltip="Confirmar Despacho" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={confirmarDespacho} />}
                <Button label="Generar Documento" tooltip="Documento" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Despachos por recintos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de despacho</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <>{_fecha.toLocaleDateString()}</>;
    };

    const hideDialog = () => {
        setProductDialog(false);
        setDetalle([]);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {detalle.confirmado !== 1 && usuario.data?.recinto && (
                    <Link to={`/PrestamoRecinto/${detalle.id}`}>
                        <Button label="Confirmar con observaciones" icon="pi pi-plus" className="p-button-warning p-mr-2" />
                    </Link>
                )}
            </React.Fragment>
        );
    };

    const estadoBodyTemplate = (rowData) => {
        let status;
        if (rowData.confirmado === 0) {
            status = {
                status: "outofstock",
                message: "Sin confirmar",
            };
        } else {
            status = {
                status: "instock",
                message: "Confirmado",
            };
        }
        return (
            <>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Despachos Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={farmacos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Prestamos Recepcionados"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="cod_despacho" header="Código Despacho" sortable></Column>
                        <Column field="cod_solicitud" header="Código Solicitud" sortable></Column>
                        <Column field="fecha" header="Fecha Despacho" sortable body={fechaBodyTemplate}></Column>
                        <Column field="recinto" header="Recinto quien realiza el Prestamo" sortable></Column>
                        <Column field="respuesta" header="Estado" sortable body={estadoBodyTemplate}></Column>
                        <Column header="Opciones" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header={`Detalles Despacho ${detalle?.cod_despacho}`} modal className="p-fluid" onHide={hideDialog}>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        {loading2 && <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />}
                        <DataTable
                            ref={dt}
                            value={detalles}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter2}
                            header={header2}
                            loading={loading2}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="cantidad" header="Cantidad" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="laboratorio" header="Laboratorio" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaVencimientoBodyTemplate} sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

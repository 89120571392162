import axios from "axios";

export class DespachoService {
    async despacho(despacho) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/despacho`, despacho)
            .then((response) => {

                return response.data;
            })
            .catch((error) => {

                return error;
            });
    }

    async modificarDespacho(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/modificarDespacho`, {despacho_id: data})
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateDespacho(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/updateDespacho`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async lotesFarmaco(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/lotesFarmaco`, {farmaco_id: data})
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async confirmarDespacho(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/confirmarDespacho/${id}`)
            .then((response) => {

                return response.data;
            })
            .catch((error) => {

                return error;
            });
    }

    async getDespachoByRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDespachosByRecinto/${id}`)
            .then((response) => {

                return response.data.data;
            })
            .catch((error) => {

                return error;
            });
    }

    async getDespachos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDespachos`)
            .then((response) => {

                return response.data.data;
            })
            .catch((error) => {

                return error;
            });
    }

    async getDetallesDespacho(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDetallesDespacho/${id}`)
            .then((response) => {

                return response.data.data;
            })
            .catch((error) => {

                return error;
            });
    }

    async getPendientesDeConfirmacion(recinto_id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/pendientes/${recinto_id}`)
            .then((response) => {

                return response.data.data;
            })
            .catch((error) => {

                return error;
            });
    }
}

import React from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";

export const RecepcionTutorial = () => {
    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Tutorial Recepción</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <ReactPlayer 
                        url="assets/videos/Recepcion.mp4"
                        width="100%"
                        height="100%"
                        controls
                    />
                </div>
            </div>
        </div>
    );
};

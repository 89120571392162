import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SolicitudesService } from "../service/SolicitudesService";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";
import { DespachoService } from "../service/DespachoService";

const solicitudServices = new SolicitudesService();
const despachoService = new DespachoService();

export const Solicitud = ({ farmacos, loading }) => {
    const { usuario } = useContext(UsuarioContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [seleccionados, setSeleccionados] = useState([]);
    const [reFarmacos, setReFarmacos] = useState(farmacos);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [seleccion, setSeleccion] = useState(null);
    const [pendientes, setPendientes] = useState([]);


    const toast = useRef(null);
    const dt = useRef(null);
    const dt2 = useRef(null);

    const handleSubmit = async () => {
        setLoadingDialog(true);
        if (seleccionados.length) {
            let _farmacos = seleccionados.map((data) => ({ cantidad: data.cantidad, id: data.id }));
            let _data = { usuario_id: usuario.data.sub, recinto_id: usuario.data.recinto_id, farmacos: [..._farmacos] };

            let json = JSON.stringify(_data);
            let params = "json=" + json;

            let res = await solicitudServices.solicitud(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    setLoadingDialog(false);
                    window.open(`${process.env.REACT_APP_API_URL}/api/solicitudPDF/${res.data.cod_solicitud}`);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    setLoadingDialog(false);
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Debe seleccionar fármacos o insumos médicos", life: 3000 });
            setLoadingDialog(false);
            return;
        }
        setLoadingDialog(false);
        setSeleccionados([]);
        setReFarmacos(farmacos);
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const cantidadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cantidad</span>
                {rowData.cantidad}
            </>
        );
    };

    const dosificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dosificación</span>
                {rowData.dosificacion}
            </>
        );
    };

    const formaFarmeceuticaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Forma Farmaceutica</span>
                {rowData.forma_farmaceutica.nombre}
            </>
        );
    };
    const nombre2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.farmaco.nombre}
            </>
        );
    };

    const dosificacion2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dosificación</span>
                {rowData.farmaco.dosificacion}
            </>
        );
    };

    const formaFarmeceutica2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Forma Farmaceutica</span>
                {rowData.farmaco.forma_farmaceutica.nombre}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Fármaco o Insumo Médico" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSeleccion(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        if (usuario.data.recinto_id <= 5 && rowData.farmaco.grupo_id !== 32 && rowData.farmaco.forma_farmaceutica_id !== 47) {
            let _seleccionados = [...seleccionados];
            let _farmaco = { ...rowData.farmaco, cantidad: rowData.cantidad - rowData.stock, cantidad_ideal: rowData.cantidad, stock: rowData.stock };
            _seleccionados.push(_farmaco);
            setSeleccionados(_seleccionados);
            toast.current.show({ severity: "success", summary: "Éxito", detail: `Fármaco o Insumo Médico seleccionado correctamente`, life: 3000 });
            hideDialog();
        } else {
            setSeleccion(rowData);
            setCantidadDialog(true);
        }
    };

    const sacarExistentes = async () => {
        let _farmacos = [...farmacos];
        seleccionados.forEach((data1) => {
            let array = _farmacos.filter((data2) => data2.farmaco_id !== data1.id);
            _farmacos = [...array];
        });
        setReFarmacos(_farmacos);
    };

    useEffect(() => {
        sacarExistentes();
        despachoService.getPendientesDeConfirmacion(usuario.data.recinto_id).then(data => setPendientes(data));
    }, [seleccionados]);

    const deleteSeleccion = (rowData) => {
        let _selecionados = seleccionados.filter((data) => data.id !== rowData.id);
        setSeleccionados(_selecionados);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Fármaco o Insumo Médico quitado correctamente`, life: 3000 });
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const saveSeleccion = () => {
        setSubmitted(true);
        if (cantidad < 1) {
            return;
        }
        let _seleccionados = [...seleccionados];
        let _farmaco = { ...seleccion.farmaco, cantidad: cantidad, cantidad_ideal: seleccion.cantidad, stock: seleccion.stock };
        _seleccionados.push(_farmaco);
        setSeleccionados(_seleccionados);
        hideCantidad();
        hideDialog();
        setCantidad(0);
        setSubmitted(false);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setSubmitted(false);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {cantidad > 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <h5>Fármacos e Insumos Médicos seleccionados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let validacion = seleccionados.length ? false : true;

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Solicitud Bodegas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <h5>Registrar Solicitud</h5>
                                    <div className="p-field">
                                        <label htmlFor="nombre">Nombre</label>
                                        <InputText id="nombre" value={`${usuario.data.nombre} ${usuario.data.apellido_paterno} ${usuario.data.apellido_materno}`} disabled type="text" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="numero">Recinto</label>
                                        <InputText id="numero" value={usuario.data.recinto} disabled type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-justify-between">

                            {
                                pendientes.length <= 0
                                    ?
                                    (<>
                                        <Button tooltip="Agregar Fármaco o Insumo Médico" onClick={openNew}>Agregar Fármaco o Insumo Médico</Button>
                                        <Button tooltip="Registrar Solicitud" className="p-button p-button-success p-mr-2" disabled={validacion} onClick={handleSubmit}>
                                            Registrar
                                        </Button>
                                    </>
                                    ) :
                                    (<Button tooltip="No se puede registrar la solicitud mientras tenga despachos pendientes de confirmar" className="p-button p-button-danger p-mr-2">
                                        Despachos pendientes de Confirmación
                                    </Button>)
                            }

                        </div>
                    </div>

                    <br />

                    <DialogProcesando visible={loadingDialog} />

                    <DataTable
                        ref={dt2}
                        value={seleccionados}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter}
                        header={header2}
                    >
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="dosificacion" header="Dosificación" sortable body={dosificacionBodyTemplate}></Column>
                        <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceuticaBodyTemplate}></Column>
                        <Column field="cantidad" header="Cantidad" sortable body={cantidadBodyTemplate}></Column>
                        <Column header="Eliminar" body={actionDeleteBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Fármaco O Insumo Médico" modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={reFarmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e insumos Médicos"
                            globalFilter={globalFilter2}
                            header={header}
                        >
                            <Column field="farmaco.nombre" header="Nombre" sortable body={nombre2BodyTemplate}></Column>
                            <Column field="farmaco.dosificacion" header="Dosificación" sortable body={dosificacion2BodyTemplate}></Column>
                            <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceutica2BodyTemplate}></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>
                    <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Cantidad a seleccionar" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && !cantidad })} />
                            {submitted && !cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                        </div>
                    </Dialog>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

import React, { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { RecintosFarmacosService } from "../service/RecintosFarmacosService";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const recintosFarmacosServices = new RecintosFarmacosService();

export const RecintoFarmacos = () => {
    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [farmacos, setFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        if (usuario.data?.recinto_id) {
            recintosFarmacosServices.getRecintosFarmacos(usuario.data.recinto_id).then((data) => {
                setFarmacos(data[0]);
                setLoading(false);
            });
        } else {
            recintosFarmacosServices.getRecintosFarmacos(id).then((data) => {
                setFarmacos(data[0]);
                setLoading(false);
            });
        }
    }, []);

    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.stock >= rowData.cantidad) {
            status = {
                status: "instock",
                message: "Con Stock",
            };
        } else {
            status = {
                status: "outofstock",
                message: "Stock Critico",
            };
        }
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Fármacos e Insumos Médicos</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}
                    <DataTable
                        ref={dt}
                        value={farmacos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                        <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                        <Column field="cantidad" header="Cantidad ideal"></Column>
                        <Column field="stock" header="Stock"></Column>
                        <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                        <Column field="estado" header="Estado" body={statusBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SalidasService } from "../service/SalidasService";
import { DespachoRecintoService } from "../service/DespachoRecintoService";
import { DialogProcesando } from "../components/DialogProcesando";

const salidasService = new SalidasService();
const despachoRecintoService = new DespachoRecintoService();

export const PrestamoRecinto = () => {
    const { id } = useParams();
    const { usuario } = useContext(UsuarioContext);
    const [detalles, setDetalles] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [lote, setLote] = useState({ recepcionado: 0 });
    const [prestamo, setPrestamo] = useState(null);
    const [loading2, setLoading2] = useState(true);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachoRecintoService.getDetallesDespachoByRecinto(id).then((data) => {
            let _detalle = data.map((lot) => {
                return { ...lot, recepcionado: lot.cantidad };
            });
            setDetalles(_detalle);
            setLoading2(false);
        });
        despachoRecintoService.getPrestamoRecinto(id).then((data) => {
            setPrestamo(data);
        });
    }, [id]);

    if (redirect) {
        return <Redirect to={`/`} />;
    }

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha de despacho</span>
                {_fecha.toLocaleDateString()}
            </>
        );
    };

    const header2 = (
        <div className="table-header">
            <h5 className="p-m-0">Lotes registrados en el despacho</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const seleccionarFarmaco = (rowData) => {
        setLote(rowData);
        setProductDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const hideDialog = () => {
        setProductDialog(false);
        setLote({ recepcionado: 0 });
    };

    const cambiarCantidadRecepcionado = () => {
        detalles.forEach((data) => {
            if (data.cod_lote === lote.cod_lote) {
                data.recepcionado = lote.recepcionado;
            }
        });
        setProductDialog(false);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Cantidad recepcionada cambiada correctamente`, life: 3000 });
    };

    let valido = lote.cantidad < lote.recepcionado ? false : true;

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {valido && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={cambiarCantidadRecepcionado} />}
        </>
    );

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _farmaco = { ...lote };
        _farmaco[`${name}`] = val;

        setLote(_farmaco);
    };

    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.cantidad === rowData.recepcionado) {
            status = {
                status: "instock",
                message: rowData.recepcionado,
            };
        } else {
            status = {
                status: "outofstock",
                message: rowData.recepcionado,
            };
        }
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const confirmarRecepcion = async () => {
        setLoadingDialog(true);
        let _lotes = detalles.filter((data) => data.cantidad !== data.recepcionado);

        let array = _lotes.map((data) => {
            return {
                cantidad: data.cantidad - data.recepcionado,
                motivo: `Faltantes en despacho del préstamo ${prestamo.cod_despacho}`,
                cod_lote: data.cod_lote,
                farmaco_id: data.farmaco_id,
            };
        });

        let _data = {
            usuario_id: usuario.data.sub,
            recinto_id: usuario.data.recinto_id,
            lotes: array,
        };

        let json = JSON.stringify(_data);
        let params = "json=" + json;

        let res = await despachoRecintoService.confirmarDespachoRecinto(id);

        if (res?.status) {
            if (res.status === "success") {
                if (_lotes.length) {
                    let res2 = await salidasService.salida(params);

                    if (res2?.status) {
                        if (res2.status === "success") {
                            toast.current.show({ severity: "success", summary: "Éxito", detail: `Despacho confirmado con nuevas cantidades`, life: 3000 });
                            setTimeout(() => {
                                setRedirect(true);
                            }, 1000);
                        } else {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: `${res2.message}`, life: 3000 });
                            let errors = { ...res2.errors };
                            for (const property in errors) {
                                toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                            }
                            setLoadingDialog(false);
                            return;
                        }
                    }
                } else {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `Despacho confirmado correctamente`, life: 3000 });
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                }
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Confirmar despacho" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={confirmarRecepcion} />
            </React.Fragment>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Confirmación</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading2 ? <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" /> : <Toolbar className="p-mb-4" right={leftToolbarTemplate}></Toolbar>}
                    <DataTable
                        ref={dt}
                        value={detalles}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes de fármacos e insumos médicos"
                        globalFilter={globalFilter2}
                        header={header2}
                        loading={loading2}
                    >
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaBodyTemplate} sortable></Column>
                        <Column field="cantidad" header="Cantidad Despachada" sortable></Column>
                        <Column field="cantidad" header="Cantidad Recepcionada" body={statusBodyTemplate} sortable></Column>
                        <Column header="Cambiar cantidad recepcionada" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={productDialog} style={{ width: "600px" }} header={`Cantidad recepcionada del lote ${lote?.cod_lote}`} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="stock">Cantidad recepcionada</label>
                                <InputNumber id="stock" value={lote.recepcionado} onChange={(e) => onInputNumberChange(e, "recepcionado")} integeronly />
                                {lote.cantidad < lote.recepcionado && <small className="p-invalid">La cantidad recepcionada no puede exceder lo despachado.</small>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

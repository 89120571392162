import axios from "axios";

export class DevolucionRecintoService {

    async detallesParaDevolucion(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/detallesParaDevolucion`, data)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async devolucionRecinto(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/devolucionRecinto`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async loQueMeDevolvieron(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/loQueMeDevolvieron/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async getDevolucion(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDevolucion/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async detallesDevolucion(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/detallesDevolucion/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    
    async confirmarDevolucion(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/confirmarDevolucion`, data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

}
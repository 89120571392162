import axios from "axios";

export class PdfService {
    async lotes_bodegas_pdf(link) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/${link}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }
    async lotesRecintos(link, id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/${link}/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

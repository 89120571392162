import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { RecepcionesService } from "../service/RecepcionesService";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const recepcionesService = new RecepcionesService();

export const Recepciones = () => {

    const [recepciones, setRecepciones] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        recepcionesService.getRecepciones().then((data) => {
            setRecepciones(data);
            setLoading(false);
        });
    }, []);

    const leftToolbarTemplate = () => {
        return (
            <Link to="/recepcion">
                <Button label="Nuevo" tooltip="Nueva Recepción" icon="pi pi-plus" className="p-button-success p-mr-2" />
            </Link>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                <Link to={`/recepcion_lotes/${rowData.id}`}>{rowData.cod_recepcion}</Link>
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.usuario.nombre}
            </>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de recepción</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const facturaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Factura</span>
                {rowData.numero_factura}
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Recepciones</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Recepciones</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={recepciones}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Recepciones"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                        <Column field="usuario.nombre" header="Usuario" sortable body={nombreBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="numero_factura" header="Número de factura" sortable body={facturaBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

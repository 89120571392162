import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { SolicitudesService } from "../service/SolicitudesService";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { Redirect, useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { DespachoService } from "../service/DespachoService";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

export const Despacho = () => {
    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [farmacos, setFarmacos] = useState(null);
    const [farmaco, setFarmaco] = useState(null);
    const [lotes, setLotes] = useState(null);
    const [solicitud, setSolicitud] = useState(null);
    const [lote, setLote] = useState(null);
    const [lotesFarmaco, setLotesFarmaco] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState(null);
    const [contador, setContador] = useState(0);
    const [bandera, setBandera] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const solicitudesServices = new SolicitudesService();
        solicitudesServices.getFarmacosSolicitud(id).then((data) => {
            let _data = data[0].map((farmaco) => {
                return { ...farmaco, numero: 0, orders: [] };
            });
            setFarmacos(_data);
            setLoading2(false);
            setLotes(data[1]);
            setSolicitud(data[2][0]);
        });
    }, [id]);

    if (bandera) {
        return <Redirect to="/solicitudes" />;
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _cantidad = cantidad;
        _cantidad = val;

        setCantidad(_cantidad);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.farmaco.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.farmaco.nombre, life: 3000 });
    };

    const nombreBodyTemplate = (data) => {
        return <>{data.farmaco.nombre}</>;
    };

    const cantidadBodyTemplate = (data) => {
        return <>{data.cantidad}</>;
    };

    const numeroBodyTemplate = (data) => {
        return <>{data.numero}</>;
    };

    const formaFarmaceuticaBodyTemplate = (data) => {
        return <>{data.farmaco.forma_farmaceutica.nombre}</>;
    };

    const dosificacionBodyTemplate = (data) => {
        return <>{data.farmaco.dosificacion}</>;
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const codLoteBodyTemplate = (rowData) => {
        return <>{rowData.cod_lote}</>;
    };

    const stockBodyTemplate = (rowData) => {
        return <>{rowData.stock}</>;
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <>{_fecha.toLocaleDateString()}</>;
    };

    const actionBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.cantidad <= rowData.numero) {
            _respuesta = "Stock Completo";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    const eliminarLote = (rowData) => {
        setContador(contador - 1);
        let _farmacos = farmacos.map((data) => {
            if (data.farmaco_id === rowData.farmaco_id) {
                data.numero = data.numero - rowData.seleccionado;
            }
            let _orders = data.orders.filter((dat) => dat.cod_lote !== rowData.cod_lote);
            return { ...data, orders: [..._orders] };
        });
        setFarmacos(_farmacos);
        lotes.map((data) => {
            if (data.farmaco === rowData.farmaco_id) {
                data.lotes.map((dat) => {
                    if (dat.cod_lote === rowData.cod_lote) {
                        dat.stock = dat.stock + rowData.seleccionado;
                    }
                    return dat;
                });
            }
            return data;
        });
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={() => eliminarLote(rowData)} />
            </div>
        );
    };

    const seleccionarBodyTemplate = (rowData) => {
        return <div className="actions">{rowData.stock > 0 && <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />}</div>;
    };

    const seleccionarFarmaco = (rowData) => {
        let _lotesFarmaco = lotes.filter((data) => data.farmaco === rowData.farmaco_id);
        setFarmaco(rowData);
        setLotesFarmaco(_lotesFarmaco[0].lotes);
        setLoading(false);
        setProductDialog(true);
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setCantidadDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < farmacos.length; i++) {
            if (farmacos[i].farmaco_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const saveSeleccion = () => {
        setContador(contador + 1);
        let _lote = { ...lote, seleccionado: cantidad };
        let _farmacos = [...farmacos];
        let _index = findIndexById(_lote.farmaco_id);
        let _farmaco = farmacos.find((data) => data.farmaco_id === _lote.farmaco_id);
        let _lotExiste = _farmaco.orders.find((dat) => dat.cod_lote === _lote.cod_lote);
        if (_lotExiste) {
            _farmaco.orders.map((dat) => {
                if (dat.cod_lote === _lote.cod_lote) {
                    dat.seleccionado = dat.seleccionado + cantidad;
                }
                return dat;
            });
            _farmaco = { ..._farmaco, numero: _farmaco.numero + cantidad };
        } else {
            _farmaco = { ..._farmaco, numero: _farmaco.numero + cantidad, orders: [..._farmaco.orders, _lote] };
        }

        _farmacos[_index] = _farmaco;
        quitarLote(_lote);
        setFarmacos(_farmacos);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote seleccionado correctamente`, life: 3000 });
        setCantidadDialog(false);
        setProductDialog(false);
        setCantidad(0);
    };

    const quitarLote = (lote) => {
        lotes.forEach((data) => {
            data.lotes.forEach((dat) => {
                if (dat.cod_lote === lote.cod_lote) {
                    dat.stock = dat.stock - cantidad;
                }
            });
        });
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const saveDespacho = async () => {
        setLoadingDialog(true);
        setSubmitted(true);
        if (contador !== 0) {
            let _farmacos = [];
            farmacos.forEach((farmaco) => {
                let array = farmaco.orders.map((lote) => {
                    return { cod_lote: lote.cod_lote, seleccionado: lote.seleccionado, farmaco: lote.farmaco_id };
                });
                _farmacos = [..._farmacos, ...array];
            });

            let _despacho = {
                solicitud_id: id,
                usuario_id: usuario.data.sub,
                recinto_id: solicitud.recinto_id,
                lotes: _farmacos,
            };

            let json = JSON.stringify(_despacho);
            let params = "json=" + json;

            const despachoServices = new DespachoService();
            let res = await despachoServices.despacho(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    window.open(`${process.env.REACT_APP_API_URL}/api/despachoPDF/${res.data.despacho_cod}`);
                    setBandera(true);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }

                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `No ha seleccionado ningun lote de fármaco o insumo médico para despacho`, life: 3000 });
        }
        setSubmitted(false);
        setLoadingDialog(false);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Crear Despacho" tooltip="Crear" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={saveDespacho} />
            </React.Fragment>
        );
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setCantidad(0);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {lote?.stock - cantidad >= 0 && farmaco.numero + cantidad <= farmaco.cantidad && cantidad !== 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.farmaco.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => <span>{new Date(data.fecha_vencimiento).toLocaleDateString()}</span>}></Column>
                    <Column field="data.seleccionado" header="Cantidad Seleccionada" sortable body={(data) => <span>{data.seleccionado}</span>}></Column>
                    <Column header="Quitar" sortable body={actionDeleteBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const header1 = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid table-demo">
            <Helmet>
                <title>MATNELO - Despacho</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <h5>Detalles de despacho</h5>
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />
                    <DataTable
                        loading={loading2}
                        value={farmacos}
                        globalFilter={globalFilter1}
                        header={header1}
                        expandedRows={expandedRows}
                        className="p-datatable-responsive"
                        dataKey="id"
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand}
                        onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate}
                    >
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="farmaco.nombre" header="Nombre" body={nombreBodyTemplate} sortable></Column>
                        <Column header="Cantidad Solicitada" body={cantidadBodyTemplate}></Column>
                        <Column field="farmaco.dosificacion" header="Dosificación" sortable body={dosificacionBodyTemplate}></Column>
                        <Column header="Cantidad Seleccionada" body={numeroBodyTemplate}></Column>
                        <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmaceuticaBodyTemplate}></Column>
                        <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <DialogProcesando visible={loadingDialog} />

            <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar lotes disponibles" modal className="p-fluid" onHide={hideDialog}>
                <DataTable
                    ref={dt}
                    value={lotesFarmaco}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >
                    <Column field="cod_lote" header="Código" body={codLoteBodyTemplate}></Column>
                    <Column field="stock" header="Stock Disponible" body={stockBodyTemplate}></Column>
                    <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaVencimientoBodyTemplate}></Column>
                    <Column header="Seleccionar" body={seleccionarBodyTemplate}></Column>
                </DataTable>
            </Dialog>

            <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                <div className="p-field">
                    <label htmlFor="cantidad">Cantidad</label>
                    <div>{lote && <small>Faltantes para completar la solicitud: {farmaco.cantidad - farmaco.numero}</small>}</div>

                    <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && cantidad === 0 })} />
                    {lote && <small>Cantidad disponible del lote seleccionado: {lote.stock}</small>}
                    {submitted && cantidad === 0 && <small className="p-invalid">La cantidad es requerida.</small>}
                </div>
            </Dialog>
        </div>
    );
};

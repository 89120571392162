import classNames from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { DialogProcesando } from "../../components/DialogProcesando";
import { DespachoService } from "../../service/DespachoService";

const despachosServices = new DespachoService();

export const ModificarDespacho = () => {
    const { id } = useParams();
    const [farmacos, setFarmacos] = useState(null);
    const [farmaco, setFarmaco] = useState(null);
    const [lote, setLote] = useState(null);
    const [lotesFarmaco, setLotesFarmaco] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachosServices.modificarDespacho(id).then((data) => {
            setFarmacos(data);
            setLoading2(false);
        });
    }, [id]);

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _cantidad = cantidad;
        _cantidad = val;

        setCantidad(_cantidad);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.nombre, life: 3000 });
    };

    const hideDialog = () => {
        setLoading(true);
        setProductDialog(false);
    };

    const codLoteBodyTemplate = (rowData) => {
        return <>{rowData.cod_lote}</>;
    };

    const stockBodyTemplate = (rowData) => {
        return <>{rowData.stock}</>;
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <>{_fecha.toLocaleDateString()}</>;
    };

    const actionBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.cantidad <= rowData.seleccionado) {
            _respuesta = "Stock Completo";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    const seleccionarBodyTemplate = (rowData) => {
        return <div className="actions">{rowData.stock > 0 && <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />}</div>;
    };

    const seleccionarFarmaco = (rowData) => {
        despachosServices.lotesFarmaco(rowData.id).then((data) => {
            setLotesFarmaco(data);
            setLoading(false);
        });
        setProductDialog(true);
        setFarmaco(rowData);
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setCantidadDialog(true);
    };

    const saveSeleccion = async () => {
        setSubmitted(true);
        setLoadingDialog(true);
        let res = await despachosServices.updateDespacho({ despacho_id: id, cod_lote: lote.cod_lote, cantidad: cantidad, farmaco_id: farmaco.id });

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
        farmacos.map((data) => {
            if (data.id === farmaco.id) {
                data.seleccionado = data.seleccionado + cantidad;
                let existe = data.orders.find((dat) => dat.cod_lote === lote.cod_lote);
                if (existe) {
                    data.orders.map((dat) => {
                        if (dat.cod_lote === lote.cod_lote) {
                            dat.cantidad = dat.cantidad + cantidad;
                        }
                        return dat;
                    });
                } else {
                    data.orders = [...data.orders, { cod_lote: lote.cod_lote, cantidad: cantidad, farmaco_id: farmaco.id, fecha_vencimiento: lote.fecha_vencimiento }];
                }
            }

            return data;
        });
        setLoadingDialog(false);
        setCantidadDialog(false);
        setProductDialog(false);
        setCantidad(0);
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const hideCantidad = () => {
        setCantidadDialog(false);
        setCantidad(0);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {lote?.stock - cantidad >= 0 && farmaco.seleccionado + cantidad <= farmaco.cantidad && cantidad !== 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => <span>{new Date(data.fecha_vencimiento).toLocaleDateString()}</span>}></Column>
                    <Column field="data.cantidad" header="Cantidad Seleccionada" sortable body={(data) => <span>{data.cantidad}</span>}></Column>
                </DataTable>
            </div>
        );
    };

    const header1 = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid table-demo">
            <Helmet>
                <title>MATNELO - Despacho</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <h5>Detalles de despacho</h5>
                    <Toast ref={toast} />
                    <DataTable
                        loading={loading2}
                        value={farmacos}
                        globalFilter={globalFilter1}
                        header={header1}
                        expandedRows={expandedRows}
                        className="p-datatable-responsive"
                        dataKey="id"
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand}
                        onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate}
                    >
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="cantidad" header="Cantidad Solicitada"></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="seleccionado" header="Cantidad Seleccionada"></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <DialogProcesando visible={loadingDialog} />

            <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar lotes disponibles" modal className="p-fluid" onHide={hideDialog}>
                <DataTable
                    ref={dt}
                    value={lotesFarmaco}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                    globalFilter={globalFilter}
                    header={header}
                    loading={loading}
                >
                    <Column field="cod_lote" header="Código" body={codLoteBodyTemplate}></Column>
                    <Column field="stock" header="Stock Disponible" body={stockBodyTemplate}></Column>
                    <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaVencimientoBodyTemplate}></Column>
                    <Column header="Seleccionar" body={seleccionarBodyTemplate}></Column>
                </DataTable>
            </Dialog>

            <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                <div className="p-field">
                    <label htmlFor="cantidad">Cantidad</label>
                    <div>{lote && <small>Faltantes para completar la solicitud: {farmaco.cantidad - farmaco.seleccionado}</small>}</div>

                    <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && cantidad === 0 })} />
                    {lote && <small>Cantidad disponible del lote seleccionado: {lote.stock}</small>}
                    {submitted && cantidad === 0 && <small className="p-invalid">La cantidad es requerida.</small>}
                </div>
            </Dialog>
        </div>
    );
};

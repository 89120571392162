import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SolicitudesService } from "../service/SolicitudesService";
import { LotesService } from "../service/LotesService";
import { Helmet } from "react-helmet";
import { Card } from "primereact/card";
import { RecintosService } from "../service/RecintosService";
import { Link } from "react-router-dom";
import { TemperaturasServices } from "../service/TemperaturasServices";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { BodegasService } from "../service/BodegasService";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { Temperaturas } from "./Temperaturas";
import { InputText } from "primereact/inputtext";

const temperaturasServices = new TemperaturasServices();
const bodegasServices = new BodegasService();
const lotesServices = new LotesService();
const solicitudesService = new SolicitudesService();
const recintosServices = new RecintosService();

export const Dashboard = ({ farmacos, loading }) => {
    let temperaturaVacia = {
        id: null,
        ambiental: null,
        fecha: null,
        bodega_id: null,
        created_at: null,
        maxima: null,
        minima: null
    };

    let es = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Limpiar",
        dateFormat: "dd/mm/yy",
        weekHeader: "Sm",
    };

    const { usuario } = useContext(UsuarioContext);
    const [stockFarmacos, setStockFarmacos] = useState([]);
    const [solicitudes, setSolicitudes] = useState([]);
    const [vencimientos, setVencimientos] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [temperatura, setTemperatura] = useState(temperaturaVacia);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading2, setLoading2] = useState(true);
    const [bodegas, setBodegas] = useState([]);
    const [loading3, setLoading3] = useState(true);
    const [recintos, setRecintos] = useState([]);
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        farmacosStockBajo(farmacos);
    }, [farmacos]);

    useEffect(() => {
        solicitudesService.getSolicitudesNuevas().then((data) => {
            setSolicitudes(data);
            setLoading2(false);
        });
        lotesServices.getVencimientosBodegas().then((data) => {
            setVencimientos([...data.recintos, ...data.lotes]);
            setLoading3(false);
        });
        recintosServices.getRecintos().then((data) => {
            setRecintos(data);
        });

        bodegasServices.getBodegas().then((data) => {
            setBodegas(data);
        });
    }, []);

    const hideDialog = () => {
        setProductDialog(false);
    };

    const saveProduct = async () => {
        let res = await temperaturasServices.temperatura(temperatura);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                hideDialog();
                setSubmitted(false);
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }

                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            return;
        }
    };

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const farmacosStockBajo = (farmacos) => {
        let _farmacos = farmacos.filter((data) => data.cantidad <= data.stock_critico && data.desactivado === 0);
        setStockFarmacos(_farmacos);
    };

    const fecha = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <div>{_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}</div>
            </>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    const idBodyTemplate = (rowData) => {
        let respuesta;
        if ((usuario.data.cargo_id === 1 || usuario.data.cargo_id === 2) && rowData.estado_id === 1) {
            respuesta = <Link to={`/despacho/${rowData.id}`}>{rowData.cod_solicitud}</Link>;
        } else {
            respuesta = rowData.cod_solicitud;
        }
        return <>{respuesta}</>;
    };

    const onBodegaChange = (e) => {
        let _bodega = { ...temperatura };
        _bodega["bodega_id"] = e.value;
        setTemperatura(_bodega);
    };

    const click = () => {
        setProductDialog(true);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _temperatura = { ...temperatura };
        _temperatura[`${name}`] = val;

        setTemperatura(_temperatura);
    };

    const rechazarSolicitud = async (rowData) => {
        let res = await solicitudesService.rechazarSolicitud(rowData.id);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                let _solicitudes = solicitudes.filter((data) => data.id !== rowData.id);
                setSolicitudes(_solicitudes);
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }

                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            return;
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-ban" tooltip="Rechazar Solicitud" className="p-button-rounded p-button-danger p-mr-2" onClick={() => rechazarSolicitud(rowData)} />
            </div>
        );
    };

    const cols = [
        { field: 'nombre', header: 'Nombre' },
        { field: 'dosificacion', header: 'Dosificación' },
        { field: 'grupo', header: 'Grupo' },
        { field: 'forma_farmaceutica', header: 'Forma Farmacéutica' },
        { field: 'cantidad', header: 'Stock actual' },
        { field: 'stock_critico', header: 'Stock minimo' }
    ];

    const setearFarmacos = (data) => {
        return data.map(farmaco => {
            return {
                nombre: farmaco.nombre,
                grupo: farmaco.grupo.nombre,
                forma_farmaceutica: farmaco.forma_farmaceutica.nombre,
                observacion: farmaco.observacion,
                dosificacion: farmaco.dosificacion,
                cantidad: farmaco.cantidad,
                stock_critico: farmaco.stock_critico,
            }
        })
    }

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        const farmacosSeteados = setearFarmacos(stockFarmacos);
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, farmacosSeteados);
                doc.save('products.pdf');
            })
        })
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    const exportExcel = () => {
        const farmacosSeteados = setearFarmacos(stockFarmacos);
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(farmacosSeteados);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Stocks criticos');
        });
    }


    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Fármacos e Insumos Médicos</h5>
            <div className="p-d-flex p-ai-center export-buttons">
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" tooltip="PDF" />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
                </span>
            </div>

        </div>
    );

    return (
        <>
            <Helmet>
                <title>Inicio</title>
            </Helmet>
            <Toast ref={toast} />
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">Quiebres de stock</span>
                        <span className="detail">Número de fármacos e insumos médicos</span>
                        <span className="count purchases" style={{ backgroundColor: "#ef6262" }}>
                            {stockFarmacos.length}
                        </span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Fármacos e Insumos Médicos con quiebre de stock</h1>
                        <DataTable ref={dt} value={stockFarmacos} globalFilter={globalFilter} header={header} loading={loading} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                            <Column field="cantidad" header="Stock actual" sortable></Column>
                            <Column field="stock_critico" header="Stock minimo" sortable></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card summary">
                        <span className="title">Solicitudes sin revisar</span>
                        <span className="detail">Número de solicitudes</span>
                        <span className="count purchases">{solicitudes.length}</span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Nuevas Solicitudes</h1>
                        <DataTable value={solicitudes} loading={loading2} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="cod_solicitud" header="Código Solicitud" sortable body={idBodyTemplate}></Column>
                            <Column field="usuario.nombre" header="Usuario" sortable></Column>
                            <Column field="recinto.nombre" header="Recinto" sortable></Column>
                            <Column field="fecha" header="Fecha" body={fecha} sortable></Column>
                            <Column header="Acción" body={actionBodyTemplate} sortable></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Proximos a vencer</span>
                        <span className="detail">Número de lotes</span>
                        <span className="count" style={{ backgroundColor: "#ef6262" }}>
                            {vencimientos.length}
                        </span>
                    </div>
                </div>
                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Lotes con fecha de vencimiento proxima</h1>
                        <DataTable value={vencimientos} loading={loading3} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="stock" header="Stock disponible" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaBodyTemplate} sortable></Column>
                            <Column field="recinto" header="Recinto" sortable></Column>
                        </DataTable>
                    </div>
                </div>

                {recintos.map((recinto) => (
                    <div key={recinto.id} className="p-col-12 p-lg-4">
                        <div className="p-card">
                            <Card title={recinto.nombre}>
                                <div className="p-card-content">
                                    <Link to={`/recinto/${recinto.id}`}>Fármacos e insumos Médicos Autorizados</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/solicitudesRecinto/${recinto.id}`}>Solicitudes de Fármacos e Insumos Médicos</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/despachosRecinto/${recinto.id}`}>Despacho de Fármacos e Insumos Médicos</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/recintoFarmacos/${recinto.id}`}>Fármacos e Insumos Médicos Disponibles </Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/lotesRecinto/${recinto.id}`}>Lotes Disponibles</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/salidas/${recinto.id}`}>Salidas registradas</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/recintoPDF/${recinto.id}`}>Reportes Lotes Disponibles</Link>
                                </div>
                                <div className="p-card-content">
                                    <Link to={`/despachosfecha/${recinto.id}`}>Despachos por fechas</Link>
                                </div>
                            </Card>
                        </div>
                    </div>
                ))}

                <Button onClick={click} tooltip="Registrar Temperatura" label="Registrar Nueva Temperatura" className="p-button-outlined p-mr-2" />
                {bodegas.map((bodega) => {
                    return <Temperaturas id={bodega.id} />;
                })}

                <Dialog visible={productDialog} style={{ width: "600px" }} header="Registrar nueva tempertatura" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="p-field">
                        <label className="p-mb-3">Bodega</label>
                        <div className="p-formgrid p-grid">
                            {bodegas.map((bodega) => (
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category1" name="bodega_id" value={bodega.id} onChange={onBodegaChange} checked={temperatura.bodega_id === bodega.id} />
                                    <label htmlFor="category1">{bodega.nombre}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="p-field">
                        <label htmlFor="ambiental">Temperatura Ambiental</label>
                        <InputNumber id="ambiental" onChange={(e) => onInputNumberChange(e, "ambiental")} required autoFocus className={classNames({ "p-invalid": submitted && temperatura.ambiental })} />
                    </div>

                    <div className="p-field">
                        <label htmlFor="maxima">Temperatura Maxima</label>
                        <InputNumber id="maxima" onChange={(e) => onInputNumberChange(e, "maxima")} required autoFocus className={classNames({ "p-invalid": submitted && temperatura.maxima })} />
                    </div>

                    <div className="p-field">
                        <label htmlFor="minima">Temperatura Minima</label>
                        <InputNumber id="minima" onChange={(e) => onInputNumberChange(e, "minima")} required autoFocus className={classNames({ "p-invalid": submitted && temperatura.minima })} />
                    </div>

                    <div className="p-field">
                        <label htmlFor="fecha">Fecha </label>
                        <Calendar className="p-col-9" locale={es} value={temperatura.fecha} onChange={(e) => setTemperatura({ ...temperatura, fecha: e.value })} dateFormat="dd/mm/yy" inline />
                        {submitted && !temperatura.fecha && <small className="p-invalid">La fecha de vencimiento es requerida.</small>}
                    </div>
                </Dialog>
            </div>
        </>
    );
};

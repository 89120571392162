import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FormasFarmaceuitcasService } from "../service/FormasFarmaceuticasService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const formasFarmaceuticasService = new FormasFarmaceuitcasService();

export const FormasFarmaceuticas = () => {
    let formaFarmaceuticaVacia = {
        id: null,
        nombre: "",
    };

    const [formasFarmaceuticas, setFormasFarmaceuticas] = useState(null);
    const [formaFarmaceuticaDialog, setFormaFarmaceuticaDialog] = useState(false);
    const [formaFarmaceutica, setFormaFarmaceutica] = useState(formaFarmaceuticaVacia);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        formasFarmaceuticasService.getFormasFarmaceuticas().then((data) => {
            setFormasFarmaceuticas(data);
            setLoading(false);
        });
    }, []);

    const openNew = () => {
        setFormaFarmaceutica(formaFarmaceuticaVacia);
        setSubmitted(false);
        setFormaFarmaceuticaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setFormaFarmaceuticaDialog(false);
    };

    const saveFormaFarmacologica = async () => {
        setSubmitted(true);
        setLoadingDialog(true);

        if (formaFarmaceutica.nombre.trim()) {
            let _formasFarmaceuticas = [...formasFarmaceuticas];
            let _formaFarmaceutica = { ...formaFarmaceutica };
            if (formaFarmaceutica.id) {
                const index = findIndexById(formaFarmaceutica.id);

                let res = await formasFarmaceuticasService.updateFormaFarmaceutica(_formaFarmaceutica);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _formasFarmaceuticas[index] = _formaFarmaceutica;
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Error", detail: errors[property], life: 3000 });
                        }
                        setLoadingDialog(false);
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
            } else {
                let res = await formasFarmaceuticasService.formaFarmaceutica(_formaFarmaceutica);
                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _formaFarmaceutica = { ..._formaFarmaceutica, id: res.data.id };
                        _formasFarmaceuticas.push(_formaFarmaceutica);
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Error", detail: errors[property], life: 3000 });
                        }
                        setLoadingDialog(false);
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
            }

            setLoadingDialog(false);
            setFormasFarmaceuticas(_formasFarmaceuticas);
            setFormaFarmaceuticaDialog(false);
            setFormaFarmaceutica(formaFarmaceuticaVacia);
        }
    };

    const editProduct = (product) => {
        setFormaFarmaceutica({ ...product });
        setFormaFarmaceuticaDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < formasFarmaceuticas.length; i++) {
            if (formasFarmaceuticas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...formaFarmaceutica };
        _product[`${name}`] = val;

        setFormaFarmaceutica(_product);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" tooltip="Nueva Forma Farmacológica" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.id}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Editar Forma Farmacológica" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Formas Farmacológicas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let valido = formaFarmaceutica.nombre.length <= 45 ? false : true;

    const formaFarmaceuticaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" disabled={valido} className="p-button-text" onClick={saveFormaFarmacologica} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Formas Farmacológicas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={formasFarmaceuticas}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Formas Farmacológicas"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column header="Editar" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={formaFarmaceuticaDialog} style={{ width: "450px" }} header="Detalles Forma Farmaceutica" modal className="p-fluid" footer={formaFarmaceuticaDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={formaFarmaceutica.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !formaFarmaceutica.nombre })} />
                            {submitted && !formaFarmaceutica.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                            {formaFarmaceutica.nombre.length > 45 && <small className="p-invalid">El nombre de la Forma Farmacutica no puede exceder los 45 caracteres.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";

export class RecepcionesService {
    async getRecepciones() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recepciones`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async recepcion(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/recepcion`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getRecepcionLotes(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recepcion_lotes/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}
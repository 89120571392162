import axios from "axios";
export class CargosService {
    async getCargos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/cargos`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

}
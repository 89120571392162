import axios from "axios";

export class GruposService {
    async getGrupos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/grupos`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async grupo(grupo) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/grupo`, grupo)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateGrupo(grupo) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/grupo/${grupo.id}`, grupo)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}
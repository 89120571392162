import axios from "axios";

export class LotesService {
    async getVencimientosBodegas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/vencimiento_bodegas`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getVencidosBodegas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/vencidos_bodegas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getLotesDisponibles() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getLotesDisponibles`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getLotesRecintos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getLotesRecintos/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getLotesRecintosVencidos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lotesDisponiblesRecintoVencidos/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async bajarVencidosBodegas(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/bajar_vencidos_bodegas`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

import React, { useRef, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { UsuarioService } from "./service/UsuarioService";

const usuarioServices = new UsuarioService();

export const AppProfile = ({ usuario, setUsuario }) => {
    const contraseñaVacia = {
        pass: "",
        new_pass: "",
        repetir_contraseña: "",
        rut: usuario.data.rut,
    };

    const [expanded, setExpanded] = useState(false);
    const [productDialog, setProductDialog] = useState(false);
    const [contraseña, setContraseña] = useState(contraseñaVacia);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    let perfil;

    const onClick = (event) => {
        setExpanded((prevState) => !prevState);
        event.preventDefault();
    };

    const hideDialog = () => {
        setProductDialog(false);
        setContraseña(contraseñaVacia);
    };

    const cerrarSesion = () => {
        setUsuario(null);
    };

    const cambiarContraseña = () => {
        setProductDialog(true);
    };

    const cambiar = async () => {
        setSubmitted(true);

        if (contraseña.pass && contraseña.new_pass && contraseña.repetir_contraseña) {
            if (contraseña.new_pass === contraseña.repetir_contraseña) {
                let res = await usuarioServices.cambiarContraseña(contraseña);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        setProductDialog(false);
                        setTimeout(() => {
                            setUsuario(null);
                        }, 1000);
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `Problemas de servidor`, life: 3000 });
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Las contraseñas no coinciden`, life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Debe completar todos los datos`, life: 3000 });
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _contraseña = { ...contraseña };
        _contraseña[`${name}`] = val;

        setContraseña(_contraseña);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={cambiar} />
        </>
    );

    if(usuario.data.cargo_id === 3){
        perfil = `Encargado ${usuario.data.recinto}`
    }else{
        if(usuario.data.cargo_id === 1){
            perfil = `Administrador`
        }else{
            perfil = `Bodeguero`;
        }
    }

    return (
        <div className="layout-profile">
            <Toast ref={toast} />
            <div>
                <img src="assets/layout/images/profile.png" alt="Profile" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">
                    {usuario.data.nombre} {usuario.data.apellido_paterno}
                </span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <div>
                <span style={{color: '#FFF'}}>
                    {`Perfil: ${perfil}`} 
                </span>
            </div>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ "layout-profile-expanded": expanded })}>
                    <li>
                        <button type="button" className="p-link" onClick={cerrarSesion}>
                            <i className="pi pi-fw pi-power-off" />
                            <span>Cerrar Sesion</span>
                        </button>
                    </li>
                    <li>
                        <button type="button" className="p-link" onClick={cambiarContraseña}>
                            <i className="pi pi-fw pi-key" />
                            <span>Cambiar Contraseña</span>
                        </button>
                    </li>
                </ul>
            </CSSTransition>
            <Dialog visible={productDialog} style={{ width: "700px" }} header="Detalles Farmacos" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="pass_act">Contraseña Actual</label>
                    <Password id="pass_act" value={contraseña.pass} onChange={(e) => onInputChange(e, "pass")} required autoFocus className={classNames({ "p-invalid": submitted && !contraseña.pass })} />
                    {submitted && !contraseña.pass && <small className="p-invalid">La contraseña actual es requerida</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="new_pass">Nueva Contraseña</label>
                    <Password id="new_pass" value={contraseña.new_pass} onChange={(e) => onInputChange(e, "new_pass")} required className={classNames({ "p-invalid": submitted && !contraseña.new_pass })} />
                    {submitted && !contraseña.new_pass && <small className="p-invalid">La nueva contraseña es requerida</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="repetir_contraseña">Repetir Nueva Contraseña Actual</label>
                    <Password id="repetir_contraseña" value={contraseña.repetir_contraseña} onChange={(e) => onInputChange(e, "repetir_contraseña")} required className={classNames({ "p-invalid": submitted && !contraseña.repetir_contraseña })} />
                    {submitted && !contraseña.repetir_contraseña && <small className="p-invalid">Repetir nueva contraseña</small>}
                </div>
            </Dialog>
        </div>
    );
};

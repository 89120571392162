import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { DespachoService } from "../service/DespachoService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router";

const despachosService = new DespachoService();

export const Despachos = () => {
    const [despachos, setDespachos] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [detalles, setDetalles] = useState([]);
    const [despacho, setDespacho] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachosService.getDespachos().then((data) => {
            setDespachos(data);
            setLoading(false);
        });
    }, []);

    if (redirect) {
        return <Redirect to={`/modificar_despacho/${redirect}`} />;
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.despacho_cod}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.usuario.nombre}
            </>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de despacho</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const estadoBodyTemplate = (rowData) => {
        let status;
        if (rowData.confirmado === 0) {
            status = {
                status: "outofstock",
                message: "Sin confirmar",
            };
        } else {
            status = {
                status: "instock",
                message: "Confirmado",
            };
        }
        return (
            <>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const recintoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Factura</span>
                {rowData.recinto.nombre}
            </>
        );
    };
    const solicitudBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Solicitud</span>
                {rowData.solicitud.cod_solicitud}
            </>
        );
    };

    const verDetalles = (despacho) => {
        setDespacho(despacho);
        setLoading2(true);
        setProductDialog(true);
        despachosService.getDetallesDespacho(despacho.id).then((data) => {
            setDetalles(data[1]);
            setDespacho(data[0][0]);
            setLoading2(false);
        });
    };

    const hideDialog = () => {
        setProductDialog(false);
        setDetalles([]);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" tooltip="Detalles Despacho" className="p-button-rounded p-button-info p-mr-2" onClick={() => verDetalles(rowData)} />
                { !rowData.confirmado && <Button icon="pi pi-pencil" tooltip="Modificar Despacho" className="p-button-rounded p-button-secondary p-mr-2" onClick={() => setRedirect(rowData.id)} />}
            </div>
        );
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <span>
                {_fecha.toLocaleDateString()}
            </span>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Despachos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const exportCSV = async () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/despachoPDF/${despacho.despacho_cod}`);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Generar Documento" tooltip="Documento" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Despachos</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={despachos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Despachos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="despacho_cod" header="Código Despacho" sortable body={idBodyTemplate}></Column>
                        <Column field="usuario.nombre" header="Usuario" sortable body={nombreBodyTemplate}></Column>
                        <Column field="recinto.nombre" header="Recinto" sortable body={recintoBodyTemplate}></Column>
                        <Column field="solicitud.cod_solicitud" header="Código Solicitud" sortable body={solicitudBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="confirmado" header="Estado" sortable body={estadoBodyTemplate}></Column>
                        <Column header="Detalles" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header={`Detalles Despacho ${despacho?.despacho_cod}`} modal className="p-fluid" onHide={hideDialog}>
                        {loading2 ? (
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        ) : (
                            <>
                                <Toolbar right={rightToolbarTemplate}></Toolbar>
                                <div className="p-col-12">
                                    <div className="card">
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="firstname2">Código Despacho</label>
                                                <InputText value={despacho.despacho_cod} disabled id="firstname2" type="text" />
                                            </div>
                                        </div>
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="firstname2">Persona que Despacho</label>
                                                <InputText value={despacho.usuario.nombre} disabled id="firstname2" type="text" />
                                            </div>
                                        </div>
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className="p-field p-col-12 p-md-6">
                                                <label htmlFor="firstname2">Código Solicitud</label>
                                                <InputText value={despacho.solicitud.cod_solicitud} disabled id="firstname2" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <DataTable
                            ref={dt}
                            value={detalles}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter2}
                            header={header2}
                            loading={loading2}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="cantidad" header="Cantidad Despachada" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaVencimientoBodyTemplate} sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import React from "react";

export const AppTopbar = ({ onToggleMenu, setUsuario }) => {
    const cerrarSesion = () => {
        setUsuario(null);
    };
    return (
        <div className="layout-topbar clearfix">
            <button type="button" className="p-link layout-menu-button" onClick={onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-icons">
                <button type="button" className="p-link" onClick={cerrarSesion}>
                    <span className="">Cerrar Sesion</span>
                    <span className="layout-topbar-icon pi pi-user" />
                </button>
            </div>
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { UsuarioService } from "../service/UsuarioService";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { RadioSelect } from "../components/RadioSelect";
import { CargosService } from "../service/CargosService";
import { RecintosService } from "../service/RecintosService";
import { Password } from "primereact/password";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const usuarioServices = new UsuarioService();

export const Usuarios = () => {
    let usuarioVacio = {
        id: null,
        rut: "",
        nombre: "",
        email: "",
        apellido_paterno: "",
        apellido_materno: "",
        activo: 1,
        contraseña: "",
        cargo_id: 1,
        recinto_id: null,
    };

    const [edit, setEdit] = useState(true);
    const [usuarios, setUsuarios] = useState(null);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [usuario, setUsuario] = useState(usuarioVacio);
    const [selectedUsuarios, setSelectedUsuarios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [recintos, setRecintos] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cargos, setCargos] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.activo === 1) {
            status = {
                status: "instock",
                message: "Activado",
            };
        } else {
            status = {
                status: "outofstock",
                message: "Desactivado",
            };
        }
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    useEffect(() => {
        usuarioServices.getUsuarios().then((data) => {
            setUsuarios(data);
            setLoading(false);
        });
        const cargosServices = new CargosService();
        cargosServices.getCargos().then((data) => {
            setCargos(data);
        });
        const recintosService = new RecintosService();
        recintosService.getRecintos().then((data) => {
            setRecintos(data);
        });
    }, []);

    const openNew = () => {
        setEdit(true);
        setUsuario(usuarioVacio);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const saveProduct = async () => {
        setSubmitted(true);
        setLoadingDialog(true);

        if (usuario.nombre.trim() && usuario.rut.trim() && usuario.apellido_paterno.trim() && usuario.apellido_materno.trim() && usuario.email.trim() && usuario.contraseña.trim() && usuario.contraseña.trim()) {
            let _usuarios = [...usuarios];
            let _usuario = { ...usuario, rut: usuario.rut.replace(/\./g, "").replace(/-/g, "") };
            let _cargo = cargos.find((cargo) => cargo.id === _usuario.cargo_id);
            if (usuario.id) {
                if (_usuario.cargo_id === 3 && !_usuario.recinto_id) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `Debe seleccionar un recinto para este cargo`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
                /* Actualizar el usuario */
                const index = findIndexById(usuario.id);
                let res = await usuarioServices.updateUsuario(_usuario);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        setLoadingDialog(false);
                        _usuarios[index] = _usuario;
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        setLoadingDialog(false);
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `Problemas de servidor`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
            } else {
                /* Registrar nuevo usuario */
                if (_usuario.cargo_id === 3 && !_usuario.recinto_id) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `Debe seleccionar un recinto para este cargo`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                } else {
                    let res = await usuarioServices.usuario(_usuario);

                    if (res?.status) {
                        if (res.status === "success") {
                            toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                            setLoadingDialog(false);
                            _usuario = { ..._usuario, id: res.data.id, cargo: { ..._cargo } };
                            _usuarios.push(_usuario);
                        } else {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                            let errors = { ...res.errors };
                            for (const property in errors) {
                                toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                            }
                            setLoadingDialog(false);
                            return;
                        }
                    } else {
                        toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                        setLoadingDialog(false);
                        return;
                    }
                }
            }
            setLoadingDialog(false);
            setUsuarios(_usuarios);
            setUsuarioDialog(false);
            setUsuario(usuarioVacio);
        }
    };

    const editProduct = (product) => {
        setEdit(false);
        setUsuario({ ...product });
        setUsuarioDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const onCategoryChange = (e) => {
        let _usuario = { ...usuario };
        _usuario["cargo_id"] = e.value;
        setUsuario(_usuario);
    };

    const onRecintoChange = (e) => {
        let _usuario = { ...usuario };
        _usuario["recinto_id"] = e.value;
        setUsuario(_usuario);
    };

    const onActivoChange = (e) => {
        let _usuario = { ...usuario };
        _usuario["activo"] = e.value;
        setUsuario(_usuario);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" tooltip="Nuevo Usuario" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const rutBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rut</span>
                {rowData.rut}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const apellidoPaternoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Apellido Paterno</span>
                {rowData.apellido_paterno}
            </>
        );
    };

    const cargoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cargo</span>
                {rowData.cargo.nombre}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Editar Usuario" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Usuarios</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let valido = usuario.rut.length <= 12 && usuario.nombre.length <= 45 && usuario.apellido_paterno.length <= 45 && usuario.apellido_materno.length <= 45 && usuario.email.length <= 100 && usuario.contraseña.length <= 255 ? false : true;

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" disabled={valido} className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <>
            <Helmet>
                <title>MATNELO - Usuarios</title>
            </Helmet>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                        {loading && (
                            <>
                                <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            </>
                        )}
                        <DataTable
                            ref={dt}
                            value={usuarios}
                            selection={selectedUsuarios}
                            onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} usuarios"
                            globalFilter={globalFilter}
                            header={header}
                            loading={loading}
                        >
                            <Column field="rut" header="Rut" sortable body={rutBodyTemplate}></Column>
                            <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                            <Column field="apellido_paterno" header="Apellido Paterno" sortable body={apellidoPaternoBodyTemplate}></Column>
                            <Column field="cargo.nombre" header="Cargo" sortable body={cargoBodyTemplate}></Column>
                            <Column field="estado" header="Estado" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                        <DialogProcesando visible={loadingDialog} />

                        <Dialog visible={usuarioDialog} style={{ width: "450px" }} header="Detalles Usuario" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="rut">Rut</label>
                                <InputText id="rut" value={usuario.rut} onChange={(e) => onInputChange(e, "rut")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.rut })} />
                                {submitted && !usuario.rut && <small className="p-invalid">El rut es requerido</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="nombre">Nombre</label>
                                <InputText id="nombre" value={usuario.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.nombre })} />
                                {submitted && !usuario.nombre && <small className="p-invalid">El nombre es requerido</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="apellido_paterno">Apellido Paterno</label>
                                <InputText id="apellido_paterno" value={usuario.apellido_paterno} onChange={(e) => onInputChange(e, "apellido_paterno")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.apellido_paterno })} />
                                {submitted && !usuario.apellido_paterno && <small className="p-invalid">El Apellido paterno es requerido</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="apellido_materno">Apellido Materno</label>
                                <InputText id="apellido_materno" value={usuario.apellido_materno} onChange={(e) => onInputChange(e, "apellido_materno")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.apellido_materno })} />
                                {submitted && !usuario.apellido_materno && <small className="p-invalid">El Apellido materno es requerido</small>}
                            </div>

                            <div className="p-field">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={usuario.email} onChange={(e) => onInputChange(e, "email")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.email })} />
                                {submitted && !usuario.email && <small className="p-invalid">El email es requerido</small>}
                            </div>

                            {edit && (
                                <div className="p-field">
                                    <label htmlFor="contraseña">Contraseña</label>
                                    <Password id="contraseña" value={usuario.contraseña} onChange={(e) => onInputChange(e, "contraseña")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.contraseña })} />
                                    {submitted && !usuario.contraseña && <small className="p-invalid">La contraseña es requerida</small>}
                                </div>
                            )}

                            <div className="p-field">
                                <label className="p-mb-3">Cargo</label>
                                <div className="p-formgrid p-grid">
                                    {cargos.map((cargo) => (
                                        <RadioSelect key={cargo.id} onCategoryChange={onCategoryChange} usuario={usuario} cargo={cargo} />
                                    ))}
                                </div>
                            </div>

                            {usuario.cargo_id === 3 && (
                                <div className="p-field">
                                    <label className="p-mb-3">Recinto</label>
                                    <div className="p-formgrid p-grid">
                                        {recintos.map((recinto) => (
                                            <div className="p-field-radiobutton p-col-6">
                                                <RadioButton inputId="category1" name="recinto_id" value={recinto.id} onChange={onRecintoChange} checked={usuario.recinto_id === recinto.id} />
                                                <label htmlFor="category1">{recinto.nombre}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {usuario.id && usuario?.cargo.nombre !== "administrador" && (
                                <div className="p-field">
                                    <label className="p-mb-3">Estado</label>
                                    <div className="p-formgrid p-grid">
                                        <div className="p-field-radiobutton p-col-6">
                                            <RadioButton inputId="activo" name="activo" value={1} onChange={onActivoChange} checked={usuario.activo === 1} />
                                            <label htmlFor="activo">Activado</label>
                                        </div>
                                        <div className="p-field-radiobutton p-col-6">
                                            <RadioButton inputId="desactivado" name="activo" value={0} onChange={onActivoChange} checked={usuario.activo === 0} />
                                            <label htmlFor="desactivado">Desactivado</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

import axios from "axios";

export class TemperaturasServices {
    async getTemperaturas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getTemperaturas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async temperatura(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/temperatura`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getTemperaturasByRecinto(id, data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/getTemperaturasByRecinto/${id}`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

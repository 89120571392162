import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SolicitudesService } from "../service/SolicitudesService";
import { Link, Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import { Helmet } from "react-helmet";

const solicitudesService = new SolicitudesService();

export const Solicitudes = () => {
    const { usuario } = useContext(UsuarioContext);

    const [solicitudes, setSolicitudes] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        if (usuario.data.cargo_id === 1 || usuario.data.cargo_id === 2) {
            solicitudesService.getSolicitudes().then((data) => {
                setSolicitudes(data);
                setLoading(false);
            });
        } else {
            solicitudesService.getSolicitudesById(usuario.data.recinto_id).then((data) => {
                setSolicitudes(data);
                setLoading(false);
            });
        }
    }, [usuario.data.cargo_id, usuario.data.recinto_id]);

    if (redirect) {
        return <Redirect to={`/modificar_solicitud/${redirect}`} />;
    }

    const idBodyTemplate = (rowData) => {
        let respuesta;
        if ((usuario.data.cargo_id === 1 || usuario.data.cargo_id === 2) && rowData.estado_id === 1) {
            respuesta = <Link to={`/despacho/${rowData.id}`}>{rowData.cod_solicitud}</Link>;
        } else {
            respuesta = rowData.cod_solicitud;
        }
        return (
            <>
                <span className="p-column-title">Código</span>
                {respuesta}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.usuario.nombre}
            </>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de solicitud</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const estadoBodyTemplate = (rowData) => {
        let status;
        if (rowData.estado.id === 3) {
            status = {
                status: "outofstock",
                message: "Rechazada",
            };
        } else {
            if (rowData.estado.id === 2) {
                status = {
                    status: "instock",
                    message: "Despachado",
                };
            } else {
                status = {
                    status: "lowstock",
                    message: "Sin Revisar",
                };
            }
        }
        return (
            <>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const recintoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Factura</span>
                {rowData.recinto.nombre}
            </>
        );
    };

    const exportCSV = async (rowData) => {
        window.open(`${process.env.REACT_APP_API_URL}/api/solicitudPDF/${rowData.cod_solicitud}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-print" tooltip="Documento" className="p-button-rounded p-button-help p-mr-2" onClick={() => exportCSV(rowData)} />
                {rowData.estado.id === 1 && !usuario.data?.recinto_id && <Button icon="pi pi-pencil" tooltip="Modificar" className="p-button-rounded p-button-warning p-mr-2" onClick={() => setRedirect(rowData.id)} />}
            </div>
        );
    };
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Solicitudes para bodegas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Solicitudes Bodegas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={solicitudes}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Solicitudes"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                        <Column field="usuario.nombre" header="Usuario" sortable body={nombreBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="recinto.nombre" header="Recinto" sortable body={recintoBodyTemplate}></Column>
                        <Column field="estado.nombre" header="Estado" sortable body={estadoBodyTemplate}></Column>
                        <Column header="Documento" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

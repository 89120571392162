import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { BodegasService } from "../service/BodegasService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Helmet } from "react-helmet";

const bodegasService = new BodegasService();

export const Bodegas = () => {
    let bodegaVacia = {
        id: null,
        nombre: "",
    };

    const [bodegas, setBodegas] = useState(null);
    const [bodegaDialog, setBodegaDialog] = useState(false);
    const [bodega, setBodega] = useState(bodegaVacia);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        bodegasService.getBodegas().then((data) => {
            setBodegas(data);
            setLoading(false);
        });
    }, []);

    const openNew = () => {
        setBodega(bodegaVacia);
        setSubmitted(false);
        setBodegaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setBodegaDialog(false);
    };

    const saveGrupo = async () => {
        setSubmitted(true);

        if (bodega.nombre.trim()) {
            let _bodegas = [...bodegas];
            let _bodega = { ...bodega };
            if (bodega.id) {
                const index = findIndexById(bodega.id);

                let res = await bodegasService.updateBodega(_bodega);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _bodegas[index] = _bodega;
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    return;
                }
            } else {
                let res = await bodegasService.bodega(_bodega);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _bodega = { ..._bodega, id: res.data.id };
                        _bodegas.push(_bodega);
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    return;
                }
            }

            setBodegas(_bodegas);
            setBodegaDialog(false);
            setBodega(bodegaVacia);
            setSubmitted(false);
        }
    };

    const editBodega = (bodega) => {
        setBodega({ ...bodega });
        setBodegaDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < bodegas.length; i++) {
            if (bodegas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _bodega = { ...bodega };
        _bodega[`${name}`] = val;

        setBodega(_bodega);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" tooltip="Registrar Nueva Bodega" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.id}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Editar Bodega" className="p-button-rounded p-button-success p-mr-2" onClick={() => editBodega(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Bodegas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let valido = bodega.nombre.length <= 45 ? false:true;

    const bodegaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" disabled={valido} className="p-button-text" onClick={saveGrupo} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Bodegas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={bodegas}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Bodegas"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column header="Editar" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={bodegaDialog} style={{ width: "450px" }} header="Detalles Bodega" modal className="p-fluid" footer={bodegaDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={bodega.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !bodega.nombre })} />
                            {submitted && !bodega.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                            {bodega.nombre.length > 45 && <small className="p-invalid">El nombre de la bodega no puede exceder los 45 caracteres.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ConsumoService } from "../service/ConsumoService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";

import { CustomProvider } from 'rsuite';
import DateRangePicker from 'rsuite/DateRangePicker';
import es_AR from 'rsuite/locales/es_AR';
import 'rsuite/dist/rsuite.min.css';

const consumoService = new ConsumoService();

export const Consumo = () => {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const f = new Date();

    const [farmacos, setFarmacos] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [valores, setValores] = useState([]);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const toast = useRef(null);
    const dt = useRef(null);

    const getData = (val) => {
        setLoading(true);
        consumoService.getConsumoBodegas(val).then((data) => {
            setFarmacos(data);
            data.sort(function (a, b) {
                if (a.consumo < b.consumo) {
                    return 1;
                }
                if (a.consumo > b.consumo) {
                    return -1;
                }
                return 0;
            });
            setDatos([data[0].nombre, data[1].nombre, data[2].nombre, data[3].nombre, data[4].nombre]);
            setValores([data[0].consumo, data[1].consumo, data[2].consumo, data[3].consumo, data[4].consumo]);

            setLoading(false);
        });
    }

    useEffect(() => {
        getData({ from, to });
    }, []);

    const chartData = {
        labels: datos,
        datasets: [
            {
                data: valores,
                backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#81C7", "red"],
                hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#81C7", "red"],
            },
        ],
    };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: "#495057",
            },
        },
    };

    const setearFarmacos = (data) => {
        return data.map(farmaco => {
            return {
                nombre: farmaco.nombre,
                grupo: farmaco.grupo.nombre,
                forma_farmaceutica: farmaco.forma_farmaceutica.nombre,
                dosificacion: farmaco.dosificacion,
                consumo: farmaco.consumo,
            }
        })
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const exportExcel = () => {
        const farmacosSeteados = setearFarmacos(farmacos);
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(farmacosSeteados);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Consumo Anual');
        });
    }

    const header = (
        <div className="table-header">
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" tooltip="XLS" />
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const changeDate = e => {
        setFrom(e[0]);
        setTo(e[1]);
        getData({ from: e[0], to: e[1] });
    }

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Consumo</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDireccion: 'row',
                        justifyContent: 'center',
                        margin: '20px'
                    }}>
                        <CustomProvider locale={es_AR}>
                            <DateRangePicker
                                defaultValue={[new Date(), new Date()]}
                                onChange={e => changeDate(e)}
                            />
                        </CustomProvider>
                    </div>
                    <h2 className="p-m-0">
                        Consumo de Fármacos e Insumos Médicos desde el <b>{from.toLocaleDateString('es-CL')}</b> hasta el <b>{to.toLocaleDateString('es-CL')}</b>
                    </h2>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}
                    <div className="card">
                        <Chart type="pie" data={chartData} options={lightOptions} />
                    </div>
                    <DataTable
                        ref={dt}
                        value={farmacos}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                        <Column field="consumo" header="Consumo" sortable></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

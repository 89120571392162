import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { useForm } from "../hooks/useForm";
import { PrestamosExtService } from "../service/PrestamosExt";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const prestamoExtService = new PrestamosExtService();

export const Prestamos = ({ farmacos, setFarmacos, loading }) => {
    const [values, handleInputChange, reset] = useForm({
        lugar_prestamo: "",
    });
    const { usuario } = useContext(UsuarioContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [lotesDialog, setLotesDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [seleccionados, setSeleccionados] = useState([]);
    const [cantidad, setCantidad] = useState(0);
    const [lote, setLote] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [lotesDisponibles, setLotesDisponibles] = useState([]);
    const [lotesFarmaco, setLotesFarmaco] = useState([]);
    const [submittedRecepcion, setSubmittedRecepcion] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const dt2 = useRef(null);

    useEffect(() => {
        prestamoExtService.getLotesDisponiblesExt().then((data) => {
            let _data = data.map((farmaco) => {
                return { ...farmaco, seleccionado: 0 };
            });
            setLotesDisponibles(_data);
        });
    }, []);

    const handleSubmit = async () => {
        setSubmittedRecepcion(true);
        setLoadingDialog(true);
        if (seleccionados.length && values.lugar_prestamo.length) {
            let _seleccionados = seleccionados.map((data) => {
                return {
                    cod_lote: data.cod_lote,
                    cantidad_prestada: data.seleccionado,
                    farmaco_id: data.farmaco_id,
                };
            });
            let prestamo = { lugar_prestamo: values.lugar_prestamo, usuario_id: usuario.data.sub, lotes: _seleccionados };
            let json = JSON.stringify(prestamo);
            let params = "json=" + json;

            let res = await prestamoExtService.prestamo_ext(params);

            if (res?.status) {
                if (res.status === "success") {
                    setLoadingDialog(false);
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    window.open(`${process.env.REACT_APP_API_URL}/api/prestamoExternoPDF/${res.data.prestamo_egreso_cod}`);
                    setSeleccionados([]);
                    setSubmittedRecepcion(false);
                    reset();
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    setLoadingDialog(false);
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Debe seleccionar fármacos o insumos médicos e Ingresar a quien le realizamos el préstamo  ", life: 3000 });
            setLoadingDialog(false);
            return;
        }
    };

    const fechaVencimientoBodytemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha Vencimiento</span>
                {_fecha.toLocaleDateString()}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const actionAgregarLoteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />
            </div>
        );
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setCantidadDialog(true);
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSeleccion(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        let _lotesFarmaco = lotesDisponibles.filter((lot) => lot.farmaco_id === rowData.id);
        if (_lotesFarmaco.length < 1) {
            toast.current.show({ severity: "error", summary: "Error", detail: `El Fármaco o Insumo médico seleccionado no cuenta con lotes disponibles`, life: 3000 });
            return;
        } else {
            setLotesFarmaco(_lotesFarmaco);
            setLotesDialog(true);
        }
    };

    const deleteSeleccion = (rowData) => {
        let _lote = { ...rowData, stock: rowData.stock + rowData.seleccionado, seleccionado: 0 };
        let _selecionados = seleccionados.filter((data) => data.cod_lote !== rowData.cod_lote);
        let _lotesDisponibles = lotesDisponibles.filter((data) => data.cod_lote !== _lote.cod_lote);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote quitado correctamente`, life: 3000 });
        setSeleccionados(_selecionados);
        setLotesDisponibles([..._lotesDisponibles, _lote]);
    };

    const saveSeleccion = async () => {
        setSubmitted(true);
        if (cantidad < 1) {
            return;
        }
        let _lote = { ...lote, stock: lote.stock - cantidad, seleccionado: lote.seleccionado + cantidad };

        let _lotesDisponibles = lotesDisponibles.filter((lot) => lot.cod_lote !== _lote.cod_lote);
        setLotesDisponibles([..._lotesDisponibles, _lote]);
        let _sele = seleccionados.filter(data => data.cod_lote !== _lote.cod_lote)

        let _seleccionados = [..._sele, _lote];
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote seleccionado correctamente`, life: 3000 });
        setSeleccionados(_seleccionados);
        setCantidad(0);
        hideCantidad();
        hideLotes();
        setLote(null);
        setSubmitted(false);
    };

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const hideLotes = () => {
        setLotesDialog(false);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setSubmitted(false);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {cantidad <= lote?.stock && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <h5>Fármacos e Insumos Médicos seleccionados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let validacionRegistrar = seleccionados.length && values.lugar_prestamo.length ? false : true;

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Préstamo (Egreso)</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <h5>Registrar préstamo (Egreso)</h5>
                                    <div className="p-field">
                                        <label htmlFor="nombre">Nombre de quien registra el préstamo</label>
                                        <InputText id="nombre" value={`${usuario.data.nombre} ${usuario.data.apellido_paterno} ${usuario.data.apellido_materno}`} disabled type="text" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="numero">A quien le hacemos el préstamo</label>
                                        <InputText className={classNames({ "p-invalid": submittedRecepcion && !values.lugar_prestamo })} id="numero" value={values.lugar_prestamo} onChange={handleInputChange} name="lugar_prestamo" type="text" />
                                        {submittedRecepcion && !values.lugar_prestamo && <small className="p-invalid">Quien nos hizo el préstamo es requerido.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-justify-between">
                            <Button tooltip="Agregar Fármaco o Insumo Médico" onClick={openNew}>Agregar Fármaco o Insumo Médico</Button>
                            <Button tooltip="Registrar Préstamo" disabled={validacionRegistrar} className="p-button p-button-success p-mr-2" onClick={handleSubmit}>
                                Registrar
                            </Button>
                        </div>
                    </div>

                    <br />

                    <DataTable
                        ref={dt2}
                        value={seleccionados}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter2}
                        header={header2}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="dosificacion" header="Dosificacion" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmaceutica" sortable></Column>
                        <Column field="seleccionado" header="Cantidad" sortable></Column>
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha de Vencimiento" sortable body={fechaVencimientoBodytemplate}></Column>
                        <Column header="Eliminar" body={actionDeleteBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visibkle={loadingDialog} />

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Fármaco O Insumo Médico" modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={farmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="dosificacion" header="Dosificacion" sortable></Column>
                            <Column field="grupo.nombre" header="Grupo" sortable></Column>
                            <Column field="forma_farmaceutica.nombre" header="Forma Farmaceutica" sortable></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={lotesDialog} style={{ width: "1000px" }} header="Seleccionar lotes" modal className="p-fluid" onHide={hideLotes}>
                        <DataTable
                            ref={dt}
                            value={lotesFarmaco}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="stock" header="Cantidad disponible" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha Vencimiento" sortable body={fechaVencimientoBodytemplate}></Column>
                            <Column header="Seleccionar" body={actionAgregarLoteBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Cantidad a seleccionar" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <div>
                                <small>{`Cantidad maxima a seleccionar ${lote?.stock}`}</small>
                            </div>

                            <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && !cantidad })} />
                            {submitted && !cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

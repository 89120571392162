import axios from "axios";

export class ConsumoService {
    async getConsumoBodegas(value) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/getConsumoBodegas`, value)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async despachosRecintos(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/despachosRecintos`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

}

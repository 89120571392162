import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { useParams } from "react-router-dom";
import { SalidasService } from "../service/SalidasService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Helmet } from "react-helmet";
import { Toolbar } from "primereact/toolbar";
import { DialogProcesando } from "../components/DialogProcesando";

const salidasServices = new SalidasService();

export const Salidas = () => {
    const { id } = useParams();
    const { usuario } = useContext(UsuarioContext);
    const [salidas, setSalidas] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [detalles, setDetalles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [productDialog, setProductDialog] = useState(false);
    const [salida, setSalida] = useState(null);
    const [confirmacionAnulacionDialog, setConfirmacionAnulacionDialog] = useState(false);
    const [anulacion, setAnulacion] = useState(null);
    const [loadingAnulacion, setLoadingAnulacion] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        salidasServices.getSalidasByRecinto(id).then((data) => {
            setSalidas(data);
            setLoading(false);
        });
    }, [id]);

    const hideDialog = () => {
        setProductDialog(false);
        setDetalles(null);
        setLoading2(true);
    };

    const detalleSalida = (rowData) => {
        setProductDialog(true);
        setSalida(rowData);
        salidasServices.getSalidasDetalle(rowData.id).then((data) => {
            setDetalles(data);
            setLoading2(false);
        });
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.salida_cod}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.usuario.nombre} {rowData.usuario.apellido_paterno} {rowData.usuario.aellido_materno}
            </>
        );
    };

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return (
            <>
                <span className="p-column-title">Fecha de solicitud</span>
                {_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}
            </>
        );
    };

    const recintoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Factura</span>
                {rowData.recinto.nombre}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" tooltip="Detalles Salida" className="p-button-rounded p-button-info p-mr-2" onClick={() => detalleSalida(rowData)} />
            </div>
        );
    };

    const confirmacionAnulacionSalida = ({ id }) => {
        setAnulacion(id);
        setConfirmacionAnulacionDialog(true);
    }

    const anulacionBodyTemplate = (rowData) => {
        let respuesta;
        if (rowData.anulada) {
            respuesta = (<div className="actions">
            </div>)

        } else {
            respuesta = (
                <div className="actions">
                    <Button icon="pi pi-arrow-circle-left" tooltip="Anular Salida" className="p-button-raised p-button-danger p-button-text" onClick={() => confirmacionAnulacionSalida(rowData)} />
                </div>
            );

        }

        return respuesta;
    };

    const exportCSV = async () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/salidaPDF/${salida?.salida_cod}`);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Generar Documento" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Salidas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const hideConfirmacionAnulacionDialog = () => {
        setConfirmacionAnulacionDialog(false);
        setAnulacion(null);
    }

    const anulacionSalida = () => {
        setLoadingAnulacion(true);
        salidasServices.anulacionSalida(anulacion).then(res => {
            if (res.code === 200) {
                let _salidas = salidas.map((salida) => {
                    if (salida.id === anulacion) {
                        return {
                            ...salida,
                            anulada: !salida.anulada
                        }
                    } else {
                        return {
                            ...salida
                        }
                    }
                })
                setSalidas(_salidas)
                setLoadingAnulacion(false);
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 5000 });
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `${res.message}`, life: 5000 });
                setLoadingAnulacion(false);
            }
            setConfirmacionAnulacionDialog(false);
        })
    }

    const confirmacionAnulacionDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideConfirmacionAnulacionDialog} />
            <Button label="Confirmar Anulación" icon="pi pi-check" className="p-button-text" onClick={anulacionSalida} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Salidas</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DialogProcesando visible={loadingAnulacion} />

                    <DataTable
                        ref={dt}
                        value={salidas}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Solicitudes"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="id" header="Código" sortable body={idBodyTemplate}></Column>
                        <Column field="usuario.nombre" header="Usuario" sortable body={nombreBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="recinto.nombre" header="Recinto" sortable body={recintoBodyTemplate}></Column>
                        <Column header="Detalle" body={actionBodyTemplate}></Column>
                        {usuario.data.cargo_id === 1 && <Column header="Anulación" body={anulacionBodyTemplate}></Column>}
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Detalles Salida" modal className="p-fluid" onHide={hideDialog}>
                        <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            ref={dt}
                            value={detalles}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter2}
                            header={header2}
                            loading={loading2}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="motivo" header="Motivo" sortable></Column>
                            <Column field="cantidad" header="Cantidad Salida" sortable></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={confirmacionAnulacionDialog} style={{ width: "450px" }} header="Confirmar" modal footer={confirmacionAnulacionDialogFooter} onHide={hideConfirmacionAnulacionDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />

                            <span>
                                {" "}
                                ¿Seguro que quiere anular la salida seleccionada?.
                                Luego de confirmar esta accion no tendra vuelta atras
                            </span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import React from "react";
import { RadioButton } from "primereact/radiobutton";

export const RadioSelect = ({cargo, onCategoryChange, usuario }) => {
    return (
        <div className="p-field-radiobutton p-col-6">
            <RadioButton inputId="category1" name="cargo_id" value={cargo.id} onChange={onCategoryChange} checked={usuario.cargo_id === cargo.id} />
            <label htmlFor="category1">{cargo.nombre}</label>
        </div>
    );
};

import axios from "axios";

export class FarmacosService {
    async getFarmacos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/farmacos`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async farmaco(farmaco) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/farmaco`, farmaco)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateFarmaco(farmaco) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/farmaco/${farmaco.id}`, farmaco)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

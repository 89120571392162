import React, { useContext, useRef, useState } from "react";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { Redirect } from "react-router-dom";
import { Toast } from "primereact/toast";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

export const Login = () => {
    const { register, handleSubmit } = useForm();
    const { usuario, setUsuario } = useContext(UsuarioContext);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            height: "100vh",
        },
        image: {
            backgroundImage: "url(https://images.unsplash.com/photo-1609606827702-edf12c39c486?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max)",
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();

    const onSubmit = (data) => {
        setLoading(true);
        let rut = data.rut.replace(/\./g, "").replace(/-/g, "");
        data = { ...data, rut: rut };
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/login`, data)
            .then((response) => {
                if (response.data?.token) {
                    setUsuario(response.data);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `Los datos ingresados no son correctos`, life: 3000 });
                }
                setLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `Los datos ingresados no son correctos`, life: 3000 });
                setLoading(false);
            });
    };

    if (!usuario) {
        return (
            <>
                <Helmet>
                    <title>MATNELO</title>
                </Helmet>
                <Toast ref={toast} />
                <DialogProcesando visible={loading} />
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Ingreso
                            </Typography>
                            <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
                                <TextField variant="outlined" margin="normal" required fullWidth inputRef={register} name="rut" label="Rut" type="text" id="rut" autoFocus />
                                <TextField variant="outlined" margin="normal" required fullWidth inputRef={register} label="Contraseña" type="password" id="contraseña" name="contraseña" />

                                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                    Ingresar
                                </Button>

                                <Box mt={5}></Box>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return <Redirect to={"/"} />;
    }
};

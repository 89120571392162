import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Helmet } from "react-helmet";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { RecintosFarmacosService } from "../service/RecintosFarmacosService";
import { SolicitudesRecintosService } from "../service/SolicitudesRecintosService";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";

export const DashboardPosta = () => {
    const { usuario } = useContext(UsuarioContext);
    const [vencimientos, setVencimientos] = useState([]);
    const [vencidos, setVencidos] = useState([]);
    const [farmacos, setFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading3, setLoading3] = useState(true);
    const [solicitudes, setSolicitudes] = useState([]);
    const [loading4, setLoading4] = useState(true);

    const idBodyTemplate = (rowData) => {
        return <Link to={`/despacho_recinto/${rowData.id}`}>{rowData.cod_solicitud}</Link>;
    };

    useEffect(() => {
        const recintoFarmacosServices = new RecintosFarmacosService();
        recintoFarmacosServices.getRecintosFarmacosActivos(usuario.data.recinto_id).then((data) => {
            setFarmacos(data);
            setLoading2(false);
        });
        recintoFarmacosServices.lotesDisponiblesRecintoVencimiento(usuario.data.recinto_id).then((data) => {
            setVencimientos(data);
            setLoading3(false);
        });
        recintoFarmacosServices.lotesDisponiblesRecintoVencidos(usuario.data.recinto_id).then((data) => {
            setVencidos(data);
            setLoading(false);
        });

        const solicitudesRecintosService = new SolicitudesRecintosService();
        solicitudesRecintosService.getSolicitudesExterior(usuario.data.recinto_id).then((data) => {
            setSolicitudes(data);
            setLoading4(false);
        });
    }, [usuario.data.recinto_id]);

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    const fechaSolicitudBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha);
        return <span>{_fecha.toLocaleDateString() + " " + _fecha.toLocaleTimeString()}</span>;
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Inicio</title>
            </Helmet>
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Solicitudes de Fármacos e Insumos Médicos</span>
                        <span className="detail">Numero de solicitudes</span>
                        <span className="count" style={{ backgroundColor: "#ef6262" }}>
                            {solicitudes.length}
                        </span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Solicitudes de Fármacos e Insumos Médicos</h1>
                        <DataTable value={solicitudes} loading={loading4} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="cod_solicitud" header="Código Solicitud" sortable body={idBodyTemplate}></Column>
                            <Column field="nombre" header="Nombre Solicitante" sortable></Column>
                            <Column field="fecha" header="Fecha Solicitud" body={fechaSolicitudBodyTemplate} sortable></Column>
                            <Column field="recinto" header="Recinto Solicitante" sortable></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Fármacos e Insumos Médicos con quiebre de stock</span>
                        <span className="detail"></span>
                        <span className="count" style={{ backgroundColor: "#ef6262" }}>
                            {farmacos.length}
                        </span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Fármacos e Insumos Médicos con quiebre de stock</h1>
                        <DataTable value={farmacos} globalFilter={globalFilter} header={header} loading={loading2} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                            <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                            <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                            <Column field="stock" header="Stock" sortable></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Lotes proximos a vencer</span>
                        <span className="detail">Numero de lotes</span>
                        <span className="count" style={{ backgroundColor: "#ef6262" }}>
                            {vencimientos.length}
                        </span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Lotes con fecha de vencimiento proxima</h1>
                        <DataTable value={vencimientos} loading={loading3} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="stock" header="Stock disponible" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaBodyTemplate} sortable></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Lotes vencidos</span>
                        <span className="detail">Numero de lotes</span>
                        <span className="count" style={{ backgroundColor: "#ef6262" }}>
                            {vencidos.length}
                        </span>
                    </div>
                    <div className="card">
                        <h1 style={{ fontSize: "16px" }}>Lotes vencidos</h1>
                        <DataTable value={vencidos} loading={loading} className="p-datatable-customers" rows={5} style={{ marginBottom: "20px" }} paginator>
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="stock" header="Stock disponible" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha de vencimiento" body={fechaBodyTemplate} sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";

import { Dashboard } from "./components/Dashboard";
import { DashboardPosta } from "./components/DashboardPosta";

import { Farmacos } from "./pages/Farmacos";
import { FormasFarmaceuticas } from "./pages/FormasFarmaceuticas";
import { Grupos } from "./pages/Grupos";
import { Bodegas } from "./pages/Bodegas";
import { Recintos } from "./pages/Recintos";
import { Recinto } from "./pages/Recinto";
import { Recepciones } from "./pages/Recepciones";
import { Recepcion } from "./pages/Recepcion";
import { Solicitudes } from "./pages/Solicitudes";
import { SolicitudesRecinto } from "./pages/SolicitudesRecinto";
import { DespachosRecinto } from "./pages/DespachosRecinto";
import { Despachos } from "./pages/Despachos";
import { Solicitud } from "./pages/Solicitud";
import { Salida } from "./pages/Salida";
import { SalidaVencimiento } from "./pages/SalidaVencimiento";
import { Salidas } from "./pages/Salidas";
import { Despacho } from "./pages/Despacho";
import { Vencidos } from "./pages/Vencidos";
import { Bajas } from "./pages/Bajas";
import { RecintoFarmacos } from "./pages/RecintoFarmacos";
import { LotesRecinto } from "./pages/LotesRecinto";
import { NosPrestaronListado } from "./pages/NosPrestaronListado.js";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { Usuarios } from "./pages/Usuarios";
import { FarmacosService } from "./service/FarmacosService";
import { UsuarioContext } from "./hooks/UsuarioContext";
import { RecepcionLotes } from "./pages/RecepcionLotes";
import { RecintosFarmacosService } from "./service/RecintosFarmacosService";
import { Consumo } from "./pages/Consumo";
import { NosPrestaron } from "./pages/NosPrestaron";
import { Devolvemos } from "./pages/Devolvemos";
import { DevolvemosListado } from "./pages/DevolvemosListado";
import { NosDevolvieronListado } from "./pages/NosDevolvieronListado";
import { Prestamos } from "./pages/Prestamos";
import { PrestamosListado } from "./pages/PrestamosListado";
import { NosDevolvieron } from "./pages/NosDevolvieron";
import { SolicitudRecintos } from "./pages/SolicitudRecintos";
import { SolicitudesEntreRecintos } from "./pages/SolicitudesEntreRecintos";
import { DespachoRecinto } from "./pages/DespachoRecinto";
import { DespachosRecintoByRecinto } from "./pages/DespachosRecintoByRecinto";
import { DevolucionByRecinto } from "./pages/DevolucionByRecinto";
import { DevolucionesByRecintoListado } from "./pages/DevolucionesByRecintoListado";
import { Reportes } from "./pages/Reportes";
import { Confirmacion } from "./pages/Confirmacion";
import { DevolucionRecinto } from "./pages/DevolucionRecinto";
import { RecepcionTutorial } from "./pages/tutoriales/RecepcionTutorial";
import { SolicitudParaBodegas } from "./pages/tutoriales/SolicitudParaBodegas";
import { DespachoDesdeBodegasTutorial } from "./pages/tutoriales/DespachoDesdeBodegasTutorial";
import { DevolucionEgreso } from "./pages/tutoriales/DevolucionEgreso";
import { DevolucionIngreso } from "./pages/tutoriales/DevolucionIngreso";
import { PrestamoEgreso } from "./pages/tutoriales/PrestamoEgreso";
import { PrestamoIngreso } from "./pages/tutoriales/PrestamoIngreso";
import { ConfirmacionDespachoDesdeBodegasTutorial } from "./pages/tutoriales/ConfirmacionDespachoDesdeBodegasTutorial";
import { PrestamoRecinto } from "./pages/PrestamoRecinto";
import { ConfirmacionDespachoRecintoRecinto } from "./pages/tutoriales/ConfirmacionDespachoRecintoRecinto";
import { ConfrimaciondevolucionRecintoRecinto } from "./pages/tutoriales/ConfrimaciondevolucionRecintoRecinto";
import { DespachoRecintoRecinto } from "./pages/tutoriales/DespachoRecintoRecinto";
import { DevolucionRecintoRecinto } from "./pages/tutoriales/DevolucionRecintoRecinto";
import { SolicitudRecintoRecinto } from "./pages/tutoriales/SolicitudRecintoRecinto";
import { DespachosConFecha } from "./pages/DespachosConFecha";
import { ModificarSolicitud } from "./pages/Solicitudes/ModificarSolicitud";
import { ModificarDespacho } from "./pages/Despachos/ModificarDespacho";

const farmacosService = new FarmacosService();
const recintoFarmacos = new RecintosFarmacosService();

const App = () => {
    const { usuario, setUsuario } = useContext(UsuarioContext);

    const [layoutMode] = useState("static");
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle] = useState("outlined");
    const [ripple] = useState(false);
    const [farmacos, setFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);
    const sidebar = useRef();
    let menuClick = false;

    useEffect(() => {
        if (usuario.data.recinto_id) {
            recintoFarmacos.getRecintosFarmacosActivos(usuario.data.recinto_id).then((data) => {
                setFarmacos(data);
                setLoading(false);
            });
        } else {
            farmacosService.getFarmacos().then((data) => {
                setFarmacos(data);
                setLoading(false);
            });
        }

        setInterval(() => {
            if (usuario.data.recinto_id) {
                recintoFarmacos.getRecintosFarmacosActivos(usuario.data.recinto_id).then((data) => {
                    setFarmacos(data);
                    setLoading(false);
                });
            } else {
                farmacosService.getFarmacos().then((data) => {
                    setFarmacos(data);
                    setLoading(false);
                });
            }
        }, 5*60000);
    }, [usuario.data.recinto_id]);

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                setOverlayMenuActive((prevState) => !prevState);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    let menu;

    if (usuario.data.cargo_id === 1) {
        menu = [
            { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
            { label: "Bajas", icon: "pi pi-fw pi-sign-out", to: "/bajas" },
            { label: "Bodegas", icon: "pi pi-fw pi-inbox", to: "/bodegas" },
            { label: "Consumo", icon: "pi pi-fw pi-cloud-download", to: "/consumo" },
            { label: "Despachos", icon: "pi pi-fw pi-eject", to: "/despachos" },
            {
                label: "Devoluciones",
                icon: "pi pi-fw pi-arrow-circle-down",
                items: [
                    { label: "Devoluciones (Entradas)", icon: "pi pi-fw pi-cloud-download", to: "/devoluciones_ext" },
                    { label: "Devoluciones (Salidas)", icon: "pi pi-fw pi-id-card", to: "/devoluciones_int" },
                ],
            },
            { label: "Fármacos e Insumos Médicos", icon: "pi pi-fw pi-plus-circle", to: "/farmacos" },
            { label: "Fármacos e Insumos Médicos Vencidos", icon: "pi pi-fw pi-folder", to: "/vencidos" },
            { label: "Formas Farmacológicas", icon: "pi pi-fw pi-ticket", to: "/formas_farmaceuticas" },
            { label: "Grupos", icon: "pi pi-fw pi-briefcase", to: "/grupos" },
            {
                label: "Préstamos (Egreso)",
                icon: "pi pi-fw pi-arrow-left",
                items: [
                    { label: "Nuevo Préstamo (Egreso)", icon: "pi pi-fw pi-cloud-download", to: "/prestamo_ext" },
                    { label: "Préstamos (Egreso)", icon: "pi pi-fw pi-cloud-download", to: "/prestamos_ext" },
                ],
            },
            {
                label: "Préstamos (Ingreso)",
                icon: "pi pi-fw pi-arrow-right",
                items: [
                    { label: "Nuevo Préstamo (Ingreso)", icon: "pi pi-fw pi-cloud-download", to: "/prestamo_int" },
                    { label: "Préstamos (Ingresos)", icon: "pi pi-fw pi-id-card", to: "/prestamos_int" },
                ],
            },
            {
                label: "Recepción",
                icon: "pi pi-fw pi-shopping-cart",
                items: [
                    { label: "Nueva Recepción", icon: "pi pi-fw pi-check-square", to: "/recepcion" },
                    { label: "Recepciones", icon: "pi pi-fw pi-id-card", to: "/recepciones" },
                ],
            },

            { label: "Recintos", icon: "pi pi-fw pi-map-marker", to: "/recintos" },
            {
                label: "Reportes",
                icon: "pi pi-fw pi-copy",
                items: [
                    { label: "Farmacos Bodegas", icon: "pi pi-fw pi-file", to: "/bodegaPDF" },
                    { label: "Insumos Médicos Bodegas", icon: "pi pi-fw pi-file", to: "/insumosPDF" },
                    { label: `Lotes Posta Rere`, icon: "pi pi-fw pi-file", to: `/recintoPDF/1` },
                    { label: `Lotes Posta Rio Claro`, icon: "pi pi-fw pi-file", to: `/recintoPDF/2` },
                    { label: `Lotes Posta Tomeco`, icon: "pi pi-fw pi-file", to: `/recintoPDF/3` },
                    { label: `Lotes Posta La Aguada`, icon: "pi pi-fw pi-file", to: `/recintoPDF/4` },
                    { label: `Lotes Posta Canchilla`, icon: "pi pi-fw pi-file", to: `/recintoPDF/5` },
                    { label: `Lotes CESFAM Yumbel Estación`, icon: "pi pi-fw pi-file", to: `/recintoPDF/6` },
                ],
            },

            {
                label: "Solicitud",
                icon: "pi pi-fw pi-sitemap",
                items: [{ label: "Solicitudes", icon: "pi pi-fw pi-id-card", to: "/solicitudes" }],
            },
            { label: "Usuarios", icon: "pi pi-fw pi-users", to: "/usuarios" },
            {
                label: "Tutoriales",
                icon: "pi pi-fw pi-caret-right",
                items: [
                    { label: "Recepción", icon: "pi pi-fw pi-caret-right", to: "/tutorial_recepcion" },
                    { label: "Solicitud para bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_solicitudparabodegas" },
                    { label: "Despacho hacia recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_despachodesdebodegas" },
                    { label: "Devolución Egreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_devolucionegreso" },
                    { label: "Devolución Ingreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_devolucioningreso" },
                    { label: "Préstamo Egreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_prestamoegreso" },
                    { label: "Préstamo Ingreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_prestamoingreso" },
                    { label: "Confirmación en recinto de despacho de bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_confirmaciondespachobodega" },
                ],
            },
        ];
    } else {
        if (usuario.data.cargo_id === 2) {
            menu = [
                { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
                { label: "Bodegas", icon: "pi pi-fw pi-folder", to: "/bodegas" },
                { label: "Consumo", icon: "pi pi-fw pi-cloud-download", to: "/consumo" },
                { label: "Despachos", icon: "pi pi-fw pi-eject", to: "/despachos" },
                { label: "Fármacos e Insumos Médicos Vencidos", icon: "pi pi-fw pi-folder", to: "/vencidos" },
                {
                    label: "Recepción",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        { label: "Nueva Recepcíon", icon: "pi pi-fw pi-check-square", to: "/recepcion" },
                        { label: "Recepciones", icon: "pi pi-fw pi-id-card", to: "/recepciones" },
                    ],
                },
                {
                    label: "Solicitud",
                    icon: "pi pi-fw pi-sitemap",
                    items: [{ label: "Solicitudes", icon: "pi pi-fw pi-id-card", to: "/solicitudes" }],
                },
                {
                    label: "Tutoriales",
                    icon: "pi pi-fw pi-caret-right",
                    items: [
                        { label: "Recepción", icon: "pi pi-fw pi-caret-right", to: "/tutorial_recepcion" },
                        { label: "Solicitud para bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_solicitudparabodegas" },
                        { label: "Despacho hacia recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_despachodesdebodegas" },
                        { label: "Devolución Egreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_devolucionegreso" },
                        { label: "Devolución Ingreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_devolucioningreso" },
                        { label: "Préstamo Egreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_prestamoegreso" },
                        { label: "Préstamo Ingreso", icon: "pi pi-fw pi-caret-right", to: "/tutorial_prestamoingreso" },
                        { label: "Confirmación en recinto de despacho de bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_confirmaciondespachobodega" },
                    ],
                },
            ];
        } else {
            menu = [
                { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
                { label: "Despachos por Bodegas", icon: "pi pi-fw pi-map-marker", to: `/despachosRecinto/${usuario.data.recinto_id}` },
                { label: "Despachos por Recintos", icon: "pi pi-fw pi-chevron-up", to: `/despachosByRecinto` },
                { label: "Fármacos e Insumos Médicos", icon: "pi pi-fw pi-plus-circle", to: "/recintoFarmacos/0" },
                { label: "Lotes", icon: "pi pi-fw pi-file", to: "/lotesRecinto/0" },
                { label: "Lo que me han devuelto", icon: "pi pi-fw pi-save", to: `/devolucionesByRecintoListado` },
                {
                    label: "Salidas",
                    icon: "pi pi-fw pi-sign-out",
                    items: [
                        { label: "Nueva Salida", icon: "pi pi-fw pi-check-square", to: "/salida" },
                        { label: "Salidas", icon: "pi pi-fw pi-id-card", to: `/salidas/${usuario.data.recinto_id}` },
                        { label: "Salida Vencidos", icon: "pi pi-fw pi-ban", to: "/salidaVencimiento" },
                    ],
                },
                {
                    label: "Solicitudes Bodegas",
                    icon: "pi pi-fw pi-briefcase",
                    items: [
                        { label: "Nueva Solicitud para bodegas", icon: "pi pi-fw pi-check-square", to: "/solicitud" },
                        { label: "Solicitudes para bodegas", icon: "pi pi-fw pi-id-card", to: "/solicitudes" },
                    ],
                },
                {
                    label: "Solicitudes Recintos",
                    icon: "pi pi-fw pi-desktop",
                    items: [
                        { label: "Nueva Solicitud para recintos", icon: "pi pi-fw pi-id-card pi-check-square", to: "/solicitudRecintos" },
                        { label: "Solicitudes para recintos", icon: "pi pi-fw pi-id-card", to: "/solicitudes_recintos" },
                    ],
                },
                {
                    label: "Tutoriales",
                    icon: "pi pi-fw pi-caret-right",
                    items: [
                        { label: "Solicitud para bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_solicitudparabodegas" },
                        { label: "Confirmación en recinto de despacho de bodegas", icon: "pi pi-fw pi-caret-right", to: "/tutorial_confirmaciondespachobodega" },
                        { label: "Solicitud entre Recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_solicitudrecintorecinto" },
                        { label: "Despacho (Préstamos) entre recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_despachorecintorecinto" },
                        { label: "Confirmación Despacho entre Recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_confirmaciondespachorecintorecinto" },
                        { label: "Devolución entre Recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_devolucionrecintorecinto" },
                        { label: "Confirmación Devolucion entre Recintos", icon: "pi pi-fw pi-caret-right", to: "/tutorial_confirmaciondevolucionrecintorecinto" },
                    ],
                },
            ];
        }
    }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === "static") return !staticMenuInactive;
            else if (layoutMode === "overlay") return overlayMenuActive;
            else return true;
        }

        return true;
    };

    const logo = <h1 style={{ color: "white", font: "oblique bold 120% cursive" }}>MATNELO</h1>;

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} usuario={usuario} setUsuario={setUsuario} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className="layout-sidebar layout-sidebar-dark" onClick={onSidebarClick}>
                    <div className="layout-logo">{logo}</div>
                    <AppProfile usuario={usuario} setUsuario={setUsuario} />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            <div className="layout-main">
                {!usuario?.data.recinto_id ? (
                    <Route path="/" exact>
                        <Dashboard farmacos={farmacos} loading={loading} />
                    </Route>
                ) : (
                    <Route path="/" exact>
                        <DashboardPosta />
                    </Route>
                )}
                <Route path="/farmacos">
                    <Farmacos farmacos={farmacos} setFarmacos={setFarmacos} setLoading={setLoading} loading={loading} />
                </Route>
                <Route path="/usuarios" component={Usuarios} />
                <Route path="/formas_farmaceuticas" component={FormasFarmaceuticas} />
                <Route path="/grupos" component={Grupos} />
                <Route path="/bodegas" component={Bodegas} />
                <Route path="/recintos" component={Recintos} />
                <Route path="/recepciones" component={Recepciones} />
                <Route path="/solicitudes" component={Solicitudes} />
                <Route path="/solicitudesRecinto/:id" component={SolicitudesRecinto} />
                <Route path="/modificar_solicitud/:id" component={ModificarSolicitud} />
                <Route path="/modificar_despacho/:id" component={ModificarDespacho} />
                <Route path="/despachosRecinto/:id" component={DespachosRecinto} />
                <Route path="/despachos" component={Despachos} />
                <Route path="/vencidos" component={Vencidos} />
                <Route path="/salidas/:id" component={Salidas} />
                <Route path="/bajas" component={Bajas} />
                <Route path="/salida" component={Salida} />
                <Route path="/salidaVencimiento" component={SalidaVencimiento} />
                <Route path="/consumo" component={Consumo} />
                <Route path="/recintoFarmacos/:id" component={RecintoFarmacos} />
                <Route path="/lotesRecinto/:id" component={LotesRecinto} />
                <Route path="/confirmacion/:id" component={Confirmacion} />
                <Route path="/prestamoRecinto/:id" component={PrestamoRecinto} />
                <Route path="/devolucionRecinto/:id" component={DevolucionRecinto} />
                <Route path="/devolucion_int/:id" component={Devolvemos} />
                <Route path="/devolucion_ext/:id" component={NosDevolvieron} />
                <Route path="/despacho_recinto/:id" component={DespachoRecinto} />
                <Route path="/prestamos_int" component={NosPrestaronListado} />
                <Route path="/prestamos_ext" component={PrestamosListado} />
                <Route path="/devoluciones_int" component={DevolvemosListado} />
                <Route path="/devoluciones_ext" component={NosDevolvieronListado} />
                <Route path="/solicitudes_recintos" component={SolicitudesEntreRecintos} />
                <Route path="/despachosByRecinto" component={DespachosRecintoByRecinto} />
                <Route path="/devolucionByRecinto/:id" component={DevolucionByRecinto} />
                <Route path="/despachosfecha/:id" component={DespachosConFecha} />
                <Route path="/tutorial_recepcion" component={RecepcionTutorial} />
                <Route path="/tutorial_solicitudparabodegas" component={SolicitudParaBodegas} />
                <Route path="/tutorial_despachodesdebodegas" component={DespachoDesdeBodegasTutorial} />
                <Route path="/tutorial_devolucionegreso" component={DevolucionEgreso} />
                <Route path="/tutorial_devolucioningreso" component={DevolucionIngreso} />
                <Route path="/tutorial_prestamoegreso" component={PrestamoEgreso} />
                <Route path="/tutorial_prestamoingreso" component={PrestamoIngreso} />
                <Route path="/tutorial_confirmaciondespachobodega" component={ConfirmacionDespachoDesdeBodegasTutorial} />
                <Route path="/tutorial_confirmaciondespachorecintorecinto" component={ConfirmacionDespachoRecintoRecinto} />
                <Route path="/tutorial_confirmaciondevolucionrecintorecinto" component={ConfrimaciondevolucionRecintoRecinto} />
                <Route path="/tutorial_despachorecintorecinto" component={DespachoRecintoRecinto} />
                <Route path="/tutorial_devolucionrecintorecinto" component={DevolucionRecintoRecinto} />
                <Route path="/tutorial_solicitudrecintorecinto" component={SolicitudRecintoRecinto} />
                <Route path="/devolucionesByRecintoListado" component={DevolucionesByRecintoListado} />
                <Route path="/prestamo_int">
                    <NosPrestaron farmacos={farmacos} setFarmacos={setFarmacos} loading={loading} />
                </Route>
                <Route path="/prestamo_ext">
                    <Prestamos farmacos={farmacos} setFarmacos={setFarmacos} loading={loading} />
                </Route>
                <Route path="/recinto/:id">
                    <Recinto farmacos={farmacos} />
                </Route>
                <Route path="/bodegaPDF">
                    <Reportes link={"lotes_bodegas_pdf"} />
                </Route>
                <Route path="/insumosPDF">
                    <Reportes link={"insumos_bodegas_pdf"} />
                </Route>
                <Route path="/recintoPDF/:id">
                    <Reportes link={"lotes_recintos_pdf"} />
                </Route>
                <Route path="/solicitud">
                    <Solicitud farmacos={farmacos} />
                </Route>
                <Route path="/solicitudRecintos">
                    <SolicitudRecintos farmacos={farmacos} />
                </Route>
                <Route path="/recepcion_lotes/:id">
                    <RecepcionLotes />
                </Route>
                <Route path="/recepcion">
                    <Recepcion farmacos={farmacos} setFarmacos={setFarmacos} loading={loading} />
                </Route>
                <Route path="/despacho/:id">
                    <Despacho />
                </Route>
            </div>

            <AppFooter />
            <div class="layout-mask"></div>
        </div>
    );
};

export default App;

import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { PdfService } from "../service/PdfService";
import { DialogProcesando } from "../components/DialogProcesando";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useRef } from "react";

export const Reportes = ({ link }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const dt = useRef(null);
    const { id } = useParams();

    const cols = [
        { field: "cod_lote", header: "CÓDIGO LOTE" },
        { field: "nombre", header: "NOMBRE" },
        { field: "dosificacion", header: "DOSIFICACIÓN" },
        { field: "forma_farmaceutica", header: "FORMA FARMACÉUTICA" },
        { field: "fecha_vencimiento", header: "FECHA VENCIMIENTO" },
        { field: "stock", header: "CANTIDAD" },
        { field: "recinto", header: "RECINTO" },
    ];

    useEffect(() => {
        const pdfService = new PdfService();
        if (id) {
            pdfService.lotesRecintos(link, id).then((res) => {
                const _contenido = res.map((item) => {
                    let _fecha = new Date(item.fecha_vencimiento);
                    return { ...item, fecha_vencimiento: _fecha.toLocaleDateString() };
                });
                setData(_contenido);
                setLoading(false);
            });
        } else {
            pdfService.lotes_bodegas_pdf(link).then((res) => {
                const _contenido = res.map((item) => {
                    let _fecha = new Date(item.fecha_vencimiento);
                    return { ...item, fecha_vencimiento: _fecha.toLocaleDateString() };
                });
                setData(_contenido);
                setLoading(false);
            });
        }
    }, [id, link]);

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.text("Lotes Disponibles", 10, 10);
                doc.autoTable(exportColumns, data);
                doc.save("lotes.pdf");
            });
        });
    };

    /*

    if (id) {
        window.open(`${process.env.REACT_APP_API_URL}/api/${link}/${id}`);
    } else {
        window.open(`${process.env.REACT_APP_API_URL}/api/${link}`);
    }

    return <Redirect to="/" />; */

    const header = (
        <div className="p-d-flex p-ai-center export-buttons">
            <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <>
            <div>
                <DialogProcesando visible={loading} />
                <div className="card">
                    <h5>Exportar</h5>

                    <Tooltip target=".export-buttons>button" position="bottom" />

                    <DataTable ref={dt} value={data} header={header} dataKey="id">
                        {cols.map((col, index) => (
                            <Column key={index} field={col.field} header={col.header} />
                        ))}
                    </DataTable>
                </div>
            </div>
        </>
    );
};

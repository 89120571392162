import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { TemperaturasServices } from "../service/TemperaturasServices";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

const temperaturasServices = new TemperaturasServices();

export const Temperaturas = ({ id }) => {
    const [bodega, setBodega] = useState("");
    const [ambiental, setAmbiental] = useState([]);
    const [maxima, setMaxima] = useState([]);
    const [minima, setminima] = useState([]);
    const [dias, setDias] = useState([]);
    const [date1, setDate1] = useState(new Date());

    let es = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Limpiar",
        dateFormat: "dd/mm/yy",
        weekHeader: "Sm",
    };

    const lineData = {
        labels: dias,
        datasets: [
            {
                label: "Ambiental",
                data: ambiental,
                fill: false,
                backgroundColor: "#008F39",
                borderColor: "#008F39",
            },
            {
                label: "Minima",
                data: minima,
                fill: false,
                backgroundColor: "#FF0000",
                borderColor: "#FF0000",
            },
            {
                label: "Maxima",
                data: maxima,
                fill: false,
                backgroundColor: "#0000FF",
                borderColor: "#0000FF",
            },
        ],
    };

    const cambiartemperaturas = () => {
        temperaturasServices.getTemperaturasByRecinto(id, date1).then((data) => {
            let _dias = [];
            let _ambiental = [];
            let _maxima = [];
            let _minima = [];

            data.temperaturas.forEach((_data) => {
                let _fecha = new Date(_data.fecha);

                if (_data.categoria === "Ambiental") {
                    _ambiental = [..._ambiental, _data.temperatura];
                    _dias = [..._dias, _fecha.toLocaleDateString()];
                }
                if (_data.categoria === "Maxima") {
                    _maxima = [..._maxima, _data.temperatura];
                }
                if (_data.categoria === "Minima") {
                    _minima = [..._minima, _data.temperatura];
                }
            });

            setDias(_dias.reverse());
            setAmbiental(_ambiental.reverse());
            setminima(_minima.reverse());
            setMaxima(_maxima.reverse());
        });
    };

    const obtenerTemperaturas = () => {
        temperaturasServices.getTemperaturasByRecinto(id, date1).then((data) => {
            let _dias = [];
            let _ambiental = [];
            let _maxima = [];
            let _minima = [];
            setBodega(data.bodega.nombre);

            data.temperaturas.forEach((_data) => {
                let _fecha = new Date(_data.fecha);
                if (_data.categoria === "Ambiental") {
                    _ambiental = [..._ambiental, _data.temperatura];
                    _dias = [..._dias, _fecha.toLocaleDateString()];
                }
                if (_data.categoria === "Maxima") {
                    _maxima = [..._maxima, _data.temperatura];
                }
                if (_data.categoria === "Minima") {
                    _minima = [..._minima, _data.temperatura];
                }
            });

            setDias(_dias.reverse());
            setAmbiental(_ambiental.reverse());
            setminima(_minima.reverse());
            setMaxima(_maxima.reverse());
        });
    };

    useEffect(() => {
        obtenerTemperaturas();
    }, []);

    return (
        <div className="p-col-12 p-lg-12">
            <div>
                <div className="card">
                    <h5>{bodega}</h5>
                    <div className="p-fluid p-grid p-justify-between">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="basic">Fecha</label>
                            <Calendar id="basic" locale={es} value={date1} dateFormat="dd/mm/yy" onChange={(e) => setDate1(e.value)} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <Button tooltip="Buscar Temperaturas" label={`Buscar temperaturas para ${bodega}`} icon="pi pi-search" className="p-button-info p-mr-2" onClick={cambiartemperaturas} />
                        </div>
                    </div>
                    <Chart type="line" data={lineData} />
                </div>
            </div>
        </div>
    );
};

import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { LotesService } from "../service/LotesService";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const lotesServices = new LotesService();

export const LotesRecinto = () => {
    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [reFarmacos, setReFarmacos] = useState([]);
    const [loading, setLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        if (usuario.data?.recinto_id) {
            lotesServices.getLotesRecintos(usuario.data.recinto_id).then((data) => {
                setReFarmacos(data);
                setLoading(false);
            });
        } else {
            lotesServices.getLotesRecintos(id).then((data) => {
                setReFarmacos(data);
                setLoading(false);
            });
        }
    }, [usuario.data.recinto_id, id]);

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    return (
        <div className="p-grid crud-demo">
         <Helmet>
                <title>MATNELO - Lotes</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={reFarmacos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                        globalFilter={globalFilter2}
                        header={header}
                        loading={loading}
                    >
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha Vencimiento" body={fechaBodyTemplate} sortable></Column>
                        <Column field="stock" header="Stock Disponible" sortable></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";

export class SalidasService {
    async salida(salida) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/salida`, salida)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getSalidasByRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSalidasByRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getSalidasDetalle(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSalidasDetalle/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async anulacionSalida(id){
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/anulacionSalida/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
    }
}

import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { DevolucionesExtService } from "../service/DevolucionesExt";
import { Calendar } from "primereact/calendar";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const devolucionExtService = new DevolucionesExtService();

export const NosDevolvieron = () => {
    let loteVacio = {
        cod_lote: "",
        cantidad: 0,
        fecha_vencimiento: "",
        laboratorio: "",
    };

    const { usuario } = useContext(UsuarioContext);
    const { id } = useParams();
    const [farmacos, setFarmacos] = useState(null);
    const [farmaco, setFarmaco] = useState(null);
    const [lote, setLote] = useState(loteVacio);
    const [expandedRows, setExpandedRows] = useState([]);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        devolucionExtService.getFarmacos(id).then((data) => {
            let _data = data.map((farmaco) => {
                return { ...farmaco, orders: [], numero: 0 };
            });
            setFarmacos(_data);
            setLoading2(false);
        });
    }, [id]);

    if (redirect) {
        return <Redirect to={`/devoluciones_ext`} />;
    }

    let es = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Limpiar",
        dateFormat: "dd/mm/yy",
        weekHeader: "Sm",
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: "info", summary: "Detalles", detail: event.data.nombre, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: "success", summary: "Detalles ocultos ", detail: event.data.nombre, life: 3000 });
    };

    const actionBodyTemplate = (rowData) => {
        let _respuesta;
        if (rowData.cantidad <= rowData.numero) {
            _respuesta = "Stock Completo";
        } else {
            _respuesta = (
                <div className="actions">
                    <Button icon="pi pi-plus" tooltip="Agregar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
                </div>
            );
        }
        return _respuesta;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const seleccionarFarmaco = (rowData) => {
        setFarmaco(rowData);
        setCantidadDialog(true);
    };

    const fechaVencimientoBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <>{_fecha.toLocaleDateString()}</>;
    };

    const saveSeleccion = () => {
        setSubmitted(true);
        if (!lote.cod_lote || !lote.laboratorio || !lote.cantidad || !lote.fecha_vencimiento || lote.cod_lote.length > 50 || lote.laboratorio.length > 100) {
            return;
        }
        let _lote = { ...lote, farmaco_id: farmaco.id, bodega_id: farmaco.bodega_id };
        let _farmaco = { ...farmaco, numero: farmaco.numero + lote.cantidad, orders: [...farmaco.orders, _lote] };
        let _farmacos = farmacos.filter((data) => data.id !== farmaco.id);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote agregado correctamente`, life: 3000 });
        setFarmacos([_farmaco, ..._farmacos]);
        hideCantidad();
        setSubmitted(false);
        setLote(loteVacio);
    };

    const saveDespacho = async () => {
        setLoadingDialog(true);
        let _lotes = [];
        farmacos.forEach((farmaco) => {
            farmaco.orders.forEach((order) => {
                _lotes = [..._lotes, order];
            });
        });

        if (!_lotes.length) {
            toast.current.show({ severity: "error", summary: "Error", detail: `Debe seleccionar lotes para registrar la devolución`, life: 3000 });
            setLoadingDialog(false);
            return;
        }

        let data = {
            usuario_id: usuario.data.sub,
            prestamo_id: id,
            lotes: _lotes,
        };

        let json = JSON.stringify(data);
        let params = "json=" + json;
        let res = await devolucionExtService.devolucion_ext(params);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                window.open(`${process.env.REACT_APP_API_URL}/api/devolucionExtPDF/${res.data.devolucion_ingreso_cod}`);
                setTimeout(() => {
                    setRedirect(true);
                }, 500);
            } else {
                toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
        setLoadingDialog(false);
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Crear Devolución" tooltip="Registrar Devolución" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={saveDespacho} />
            </React.Fragment>
        );
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
    };

    let validacionLote = lote.cod_lote.length <= 50 && lote.laboratorio.length <= 100 ? false : true;

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            <Button label="Guardar" icon="pi pi-check" disabled={validacionLote} className="p-button-text" onClick={saveSeleccion} />
        </>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle {data.nombre}</h5>
                <DataTable value={data.orders}>
                    <Column field="data.cod_lote" header="Código Lote" sortable body={(data) => <span>{data.cod_lote}</span>}></Column>
                    <Column field="data.fecha_vencimiento" header="fecha Vencimiento" sortable body={(data) => fechaVencimientoBodyTemplate(data)}></Column>
                    <Column field="data.cantidad" header="Cantidad Seleccionada" sortable body={(data) => <span>{data.cantidad}</span>}></Column>
                </DataTable>
            </div>
        );
    };

    return (
        <div className="p-grid table-demo">
            <Helmet>
                <title>MATNELO - Devolución (Ingreso)</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <h5>Detalles Devolución (Ingreso)</h5>
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <Toast ref={toast} />
                    <DataTable loading={loading2} value={farmacos} expandedRows={expandedRows} className="p-datatable-responsive" dataKey="id" onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}>
                        <Column expander headerStyle={{ width: "3rem" }} />
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="cantidad" header="Cantidad Prestada" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="numero" header="Cantidad Seleccionada" sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <DialogProcesando visible={loadingDialog} />

            <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                <div className="p-field">
                    <label htmlFor="cod_lote">Código de Lote</label>
                    <InputText id="cod_lote" value={lote.cod_lote} onChange={(e) => onInputChange(e, "cod_lote")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.cod_lote })} />
                    {submitted && !lote.cod_lote && <small className="p-invalid">Código del lote es requerido.</small>}
                    {lote.cod_lote.length > 50 && <small className="p-invalid">Código de lote no puede exceder los 50 caracteres.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="cod_lote">Laboratorio</label>
                    <InputText id="cod_lote" value={lote.laboratorio} onChange={(e) => onInputChange(e, "laboratorio")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.laboratorio })} />
                    {submitted && !lote.laboratorio && <small className="p-invalid">Laboratorio requerido.</small>}
                    {lote.laboratorio.length > 100 && <small className="p-invalid">El laboratorio no puede exceder los 100 caracteres.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="cantidad">Cantidad</label>
                    <InputNumber id="cantidad" value={lote.cantidad} onChange={(e) => onInputNumberChange(e, "cantidad")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.cantidad })} />
                    {submitted && !lote.cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="fecha_vencimiento">Fecha de Vencimiento</label>
                    <Calendar className="p-col-7" locale={es} value={lote.fecha_vencimiento} inline onChange={(e) => setLote({ ...lote, fecha_vencimiento: e.value })} dateFormat="dd/mm/yy" yearNavigator monthNavigator yearRange="2021:2051" />
                    {submitted && !lote.fecha_vencimiento && <small className="p-invalid">La fecha de vencimiento es requerida.</small>}
                </div>
            </Dialog>
        </div>
    );
};

import React, { useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { useForm } from "../hooks/useForm";
import { RecepcionesService } from "../service/RecepcionesService";
import { Calendar } from "primereact/calendar";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const recepcionesService = new RecepcionesService();

export const Recepcion = ({ farmacos, setFarmacos, loading }) => {
    let loteVacio = {
        cod_lote: "",
        cantidad: 0,
        fecha_vencimiento: "",
        precio: 0,
        laboratorio: "",
    };
    const [values, handleInputChange, reset] = useForm({
        numero_factura: "",
    });
    const { usuario } = useContext(UsuarioContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [cantidadFacturaDialog, setCantidadFacturaDialog] = useState(false);
    const [cantidadFactura, setCantidadFactura] = useState(0);
    const [seleccionados, setSeleccionados] = useState([]);
    const [farmaco, setFarmaco] = useState(null);
    const [lote, setLote] = useState(loteVacio);
    const [submitted, setSubmitted] = useState(false);
    const [submittedRecepcion, setSubmittedRecepcion] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const dt2 = useRef(null);

    let es = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Limpiar",
        dateFormat: "dd/mm/yy",
        weekHeader: "Sm",
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < farmacos.length; i++) {
            if (farmacos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const handleSubmit = async () => {
        setLoadingDialog(true);
        setSubmittedRecepcion(true);
        if (seleccionados.length && values.numero_factura.length) {
            let idSeleccionados = seleccionados.map((data) => {
                return {
                    id: data.id,
                    bodega_id: data.bodega_id,
                    lote: {
                        ...data.lote,
                        cod_lote: `${data.id}|${data.lote.cod_lote}`
                    },
                };
            });
            let recepcion = { ...values, farmacos: idSeleccionados, usuario: usuario.data.sub };
            let json = JSON.stringify(recepcion);
            let params = "json=" + json;

            let _farmacos = [...farmacos];
            recepcion.farmacos.forEach((farmaco) => {
                const index = findIndexById(farmaco.id);
                const _farmaco = farmacos.find((data) => data.id === farmaco.id);
                _farmacos[index] = { ..._farmaco, cantidad: _farmaco.cantidad + farmaco.lote.cantidad };
            });

            setFarmacos(_farmacos);

            let res = await recepcionesService.recepcion(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    window.open(`${process.env.REACT_APP_API_URL}/api/recepcionPDF/${res.data.cod_recepcion}`);
                    setSeleccionados([]);
                    setSubmittedRecepcion(false);
                    reset();
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: "Debe seleccionar fármacos o insumos médicos e Ingresar el número de factura", life: 3000 });
        }
        setLoadingDialog(false);
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const cantidadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cantidad</span>
                {rowData.lote.cantidad}
            </>
        );
    };

    const codLoteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cantidad</span>
                {rowData.lote.cod_lote}
            </>
        );
    };

    const dosificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dosificación</span>
                {rowData.dosificacion}
            </>
        );
    };

    const grupoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Grupo</span>
                {rowData.grupo.nombre}
            </>
        );
    };

    const precioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Precio</span>
                {rowData.lote.precio}
            </>
        );
    };

    const formaFarmeceuticaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Forma Farmaceutica</span>
                {rowData.forma_farmaceutica.nombre}
            </>
        );
    };

    const fechaVencimientoBodytemplate = (rowData) => {
        let _fecha = new Date(rowData.lote.fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha Vencimiento</span>
                {_fecha.toLocaleDateString()}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSeleccion(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        setCantidadDialog(true);
        setFarmaco(rowData);
    };

    const deleteSeleccion = (rowData) => {
        let _selecionados = seleccionados.filter((data) => data.lote.cod_lote !== rowData.lote.cod_lote);
        setSeleccionados(_selecionados);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote eliminado correcatmente`, life: 3000 });
    };

    const validarCantidad = () => {
        if (cantidadFactura === seleccionados.length) {
            hideCantidadFactura();
            handleSubmit();
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `La cantidad de lotes de la factura no coincide con la cantidad de lotes registrados`, life: 5000 });
            hideCantidadFactura();
            return;
        }
    };

    const saveSeleccion = async () => {
        setSubmitted(true);
        if (!lote.cod_lote || !lote.laboratorio || !lote.cantidad || !lote.precio || !lote.fecha_vencimiento || lote.cod_lote.length > 50 || lote.laboratorio.length > 100) {
            return;
        } else {
            let _seleccinado = { ...farmaco, lote: lote };
            let _selecionados = [...seleccionados];
            _selecionados.push(_seleccinado);
            setSeleccionados(_selecionados);
            hideCantidad();
            setLote(loteVacio);
            setSubmitted(false);
            toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote agregado correctamente`, life: 3000 });
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const onInputFacturaChange = (e) => {
        const val = e.value || 0;
        let _lote = val;

        setCantidadFactura(_lote);
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const openCantidadFactura = () => {
        setCantidadFacturaDialog(true);
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setSubmitted(false);
        setLote(loteVacio);
    };

    const hideCantidadFactura = () => {
        setCantidadFacturaDialog(false);
        setCantidadFactura(0);
    };

    let validacionLote = lote.cod_lote.length <= 50 && lote.laboratorio.length <= 100 ? false : true;

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            <Button label="Guardar" tooltip="Agregar Lote" icon="pi pi-check" disabled={validacionLote} className="p-button-text" onClick={saveSeleccion} />
        </>
    );

    const cantidadFacturaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidadFactura} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={validarCantidad} />
        </>
    );

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const header2 = (
        <div className="table-header">
            <h5>Fármacos e Insumos Médicos seleccionados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let validacionRegistrar = seleccionados.length && values.numero_factura.length ? false : true;

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Recepción</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <h5>Registrar Recepción</h5>
                                    <div className="p-field">
                                        <label htmlFor="nombre">Nombre de quien registra la recepción</label>
                                        <InputText id="nombre" value={`${usuario.data.nombre} ${usuario.data.apellido_paterno} ${usuario.data.apellido_materno}`} disabled type="text" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="numero">Número de Factura</label>
                                        <InputText className={classNames({ "p-invalid": submittedRecepcion && !values.numero_factura })} id="numero" value={values.numero_factura} onChange={handleInputChange} name="numero_factura" type="text" />
                                        {submittedRecepcion && !values.numero_factura && <small className="p-invalid">El número de factura es requerido.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-justify-between">
                            <Button tooltip="Agregar Fármaco o Insumo Médico" onClick={openNew}>Agregar Fármaco o Insumo Médico</Button>
                            <Button tooltip="Registrar Recepción" disabled={validacionRegistrar} className="p-button p-button-success p-mr-2" onClick={openCantidadFactura}>
                                Registrar
                            </Button>
                        </div>
                    </div>

                    <br />

                    <DialogProcesando visible={loadingDialog} />

                    <DataTable
                        ref={dt2}
                        value={seleccionados}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter2}
                        header={header2}
                    >
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="dosificacion" header="Dosificación" sortable body={dosificacionBodyTemplate}></Column>
                        <Column field="lote.precio" header="Precio" sortable body={precioBodyTemplate}></Column>
                        <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceuticaBodyTemplate}></Column>
                        <Column field="lote.cantidad" header="Cantidad" sortable body={cantidadBodyTemplate}></Column>
                        <Column field="lote.cod_lote" header="Código Lote" sortable body={codLoteBodyTemplate}></Column>
                        <Column field="lote.fecha_vencimiento" header="Fecha de Vencimiento" sortable body={fechaVencimientoBodytemplate}></Column>
                        <Column header="Eliminar" body={actionDeleteBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Fármaco O Insumo Médico" modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={farmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                            globalFilter={globalFilter}
                            header={header}
                        >
                            <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                            <Column field="dosificacion" header="Dosificación" sortable body={dosificacionBodyTemplate}></Column>
                            <Column field="grupo.nombre" header="Grupo" sortable body={grupoBodyTemplate}></Column>
                            <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceuticaBodyTemplate}></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <Dialog visible={cantidadDialog} style={{ width: "500px" }} header="Detalles Lote" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cod_lote">Código de Lote</label>
                            <InputText id="cod_lote" value={lote.cod_lote} onChange={(e) => onInputChange(e, "cod_lote")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.cod_lote })} />
                            {submitted && !lote.cod_lote && <small className="p-invalid">Código del lote es requerido.</small>}
                            {lote.cod_lote.length > 50 && <small className="p-invalid">Código de lote no puede exceder los 50 caracteres.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="cod_lote">Laboratorio</label>
                            <InputText id="cod_lote" value={lote.laboratorio} onChange={(e) => onInputChange(e, "laboratorio")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.laboratorio })} />
                            {submitted && !lote.laboratorio && <small className="p-invalid">Laboratorio requerido.</small>}
                            {lote.laboratorio.length > 100 && <small className="p-invalid">El laboratorio no puede exceder los 100 caracteres.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" value={lote.cantidad} onChange={(e) => onInputNumberChange(e, "cantidad")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.cantidad })} />
                            {submitted && !lote.cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="precio">Precio</label>
                            <InputNumber id="precio" value={lote.precio} onChange={(e) => onInputNumberChange(e, "precio")} required autoFocus className={classNames({ "p-invalid": submitted && !lote.precio })} />
                            {submitted && !lote.precio && <small className="p-invalid">El precio es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="fecha_vencimiento">Fecha de Vencimiento</label>
                            <Calendar id="fecha_vencimiento" locale={es} value={lote.fecha_vencimiento} inline onChange={(e) => setLote({ ...lote, fecha_vencimiento: e.value })} dateFormat="dd/mm/yy" yearNavigator monthNavigator yearRange="2021:2051" />
                            {submitted && !lote.fecha_vencimiento && <small className="p-invalid">La fecha de vencimiento es requerida.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={cantidadFacturaDialog} style={{ width: "700px" }} header="Cantidad de lotes en factura" modal className="p-fluid" footer={cantidadFacturaDialogFooter} onHide={hideCantidadFactura}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" value={cantidadFactura} onChange={(e) => onInputFacturaChange(e)} required autoFocus />
                            {cantidadFactura < 1 && <small className="p-invalid">Cantidad es requerida.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { LotesService } from "../service/LotesService";
import { InputNumber } from "primereact/inputnumber";
import { SalidasService } from "../service/SalidasService";
import { Redirect } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const lotesServices = new LotesService();

export const Salida = () => {
    const salidasService = new SalidasService();
    const { usuario } = useContext(UsuarioContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [seleccionados, setSeleccionados] = useState([]);
    const [subbmited, setSubbmited] = useState(false);
    const [motivo, setMotivo] = useState("");
    const [reFarmacos, setReFarmacos] = useState([]);
    const [farmaco, setFarmaco] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const dt2 = useRef(null);

    useEffect(() => {
        lotesServices.getLotesRecintos(usuario.data.recinto_id).then((data) => {
            setReFarmacos(data);
            setLoading(false);
        });
    }, [usuario.data.recinto_id]);

    if (redirect) {
        return <Redirect to={`/salidas/${usuario.data.recinto_id}`} />;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarFarmaco(rowData)} />
            </div>
        );
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSeleccion(rowData)} />
            </div>
        );
    };

    const guardarSalida = async () => {
        setLoadingDialog(true);
        if (seleccionados.length > 0) {
            let array = seleccionados.map((data) => {
                return {
                    cantidad: data.cantidad_seleccionada,
                    motivo: data.motivo,
                    cod_lote: data.cod_lote,
                    farmaco_id: data.farmaco_id,
                };
            });

            let _data = {
                usuario_id: usuario.data.sub,
                recinto_id: usuario.data.recinto_id,
                lotes: array,
            };


            let json = JSON.stringify(_data);
            let params = "json=" + json;

            if (!_data.lotes.length) {
                toast.current.show({ severity: "error", summary: "Error", detail: `Debe seleccionar lotes para registrar la salida`, life: 3000 });
                setLoadingDialog(false);
                return;
            }

            let res = await salidasService.salida(params);

            if (res?.status) {
                if (res.status === "success") {
                    toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                    setLoadingDialog(false);
                    window.open(`${process.env.REACT_APP_API_URL}/api/salidaPDF/${res.data.salida_cod}`);
                    setRedirect(true);
                } else {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                    let errors = { ...res.errors };
                    for (const property in errors) {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                    }
                    setLoadingDialog(false);
                    return;
                }
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `No ha seleccionado ningun lote aun`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
    };

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const onInputChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setMotivo(val);
    };

    const seleccionarFarmaco = (rowData) => {
        setFarmaco(rowData);
        setCantidadDialog(true);
    };

    const confirmarSeleccion = () => {
        setSubbmited(true);
        if (cantidad === 0) {
            toast.current.show({ severity: "error", summary: "Error", detail: `No ha seleccionado ninguna cantidad`, life: 3000 });
            return;
        } else {
            let _seleccionados = [...seleccionados, { ...farmaco, cantidad_seleccionada: cantidad, motivo: motivo }];
            let _refarmacos = reFarmacos.filter((data) => data.cod_lote !== farmaco.cod_lote);
            setFarmaco(null);
            setReFarmacos(_refarmacos);
            setCantidad(0);
            setSeleccionados(_seleccionados);
            hideDialog();
            hidecantidadDialog();
            setSubbmited(false);
            setMotivo("");
        }
    };

    const deleteSeleccion = (rowData) => {
        let _selecionados = seleccionados.filter((data) => data.cod_lote !== rowData.cod_lote);
        let _refarmacos = [...reFarmacos, rowData];
        setReFarmacos(_refarmacos);
        setSeleccionados(_selecionados);
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const hidecantidadDialog = () => {
        setSubbmited(false);
        setCantidad(0);
        setMotivo("");
        setCantidadDialog(false);
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hidecantidadDialog} />
            {farmaco?.stock >= cantidad && cantidad > 0 && motivo.length > 0 && motivo.length <= 100 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={confirmarSeleccion} />}
        </>
    );

    const header2 = (
        <div className="table-header">
            <h5>Lotes Con Salida</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Salida</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <h5>Registrar Salida</h5>
                                    <div className="p-field">
                                        <label htmlFor="nombre">Nombre</label>
                                        <InputText id="nombre" value={`${usuario.data.nombre} ${usuario.data.apellido_paterno} ${usuario.data.apellido_materno}`} disabled type="text" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="numero">Recinto</label>
                                        <InputText id="numero" value={usuario.data.recinto} disabled type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-justify-between">
                            <Button tooltip="Agregar Lote" onClick={openNew}>Agregar Lote</Button>
                            <Button tooltip="Registrar Salida" onClick={guardarSalida} className="p-button p-button-success p-mr-2">
                                Registrar
                            </Button>
                        </div>
                    </div>

                    <br />

                    <DataTable
                        ref={dt2}
                        value={seleccionados}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter}
                        header={header2}
                    >
                        <Column field="cod_lote" header="Código Lote" sortable></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="motivo" header="Motivo salida" sortable></Column>
                        <Column field="cantidad_seleccionada" header="Cantidad Seleccionada" sortable></Column>
                        <Column header="Eliminar" body={actionDeleteBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Lote" modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={reFarmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes"
                            globalFilter={globalFilter2}
                            header={header}
                            loading={loading}
                        >
                            <Column field="cod_lote" header="Código Lote" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="dosificacion" header="Dosificación" sortable></Column>
                            <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                            <Column field="stock" header="Stock Disponible" sortable></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={cantidadDialog} style={{ width: "600px" }} header="Detalles de salida del lote" modal className="p-fluid" footer={productDialogFooter} onHide={hidecantidadDialog}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="dosificacion">Motivo</label>
                                <InputTextarea id="dosificacion" value={motivo} onChange={(e) => onInputChange(e)} required autoFocus />
                                {motivo && motivo.length > 255 && <small className="p-invalid">El motivo no debe exceder los 100 caracteres.</small>}
                                {subbmited && !motivo.length && <small className="p-invalid">El motivo es requerido.</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cantidad">Cantidad</label>
                                <br></br>
                                <span>{farmaco && <small>Cantidad disponible del lote seleccionado: {farmaco.stock}</small>}</span>
                                <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} integeronly />
                                {subbmited && !cantidad && <small className="p-invalid">La cantidad es requerida.</small>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";

export class BajasServices {
    async getBajas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getBajas`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

import axios from "axios";

export class DevolucionesExtService {

    async getDevolucionesExt() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDevolucionesExt`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getDevolucionExt(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDevolucionExt/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getFarmacos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getFarmacosDevolucionExt/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async devolucion_ext(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/devolucion_ext`, data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

}
import axios from "axios";

export class DespachoRecintoService {
    async obtenerFarmacosSolicitud(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/farmacosSolicitud`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async despachoRecinto(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/despachoRecinto`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getDespachosByRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDespachosPorRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getPrestamoRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getPrestamoRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getDetallesDespachoByRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDetallesDespachoByRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async confirmarDespachoRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/confirmarDespachoRecinto/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

import axios from "axios";

export class SolicitudesRecintosService {
    async solicitud(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/solicitud_recinto`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async getSolicitudesRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/solicitudes_recinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async getDetallesSolicitudRecinto(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDetallesSolicitudRecinto/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async getSolicitudesExterior(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getSolicitudesExterior/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

}

import React, { useState, useRef, useContext, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { UsuarioContext } from "../hooks/UsuarioContext";
import { SolicitudesRecintosService } from "../service/SolicitudesRecintosService";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { InputNumber } from "primereact/inputnumber";
import classNames from "classnames";
import { DialogProcesando } from "../components/DialogProcesando";

const solicitudesRecintosService = new SolicitudesRecintosService();

export const SolicitudRecintos = ({ farmacos, loading }) => {
    const { usuario } = useContext(UsuarioContext);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [seleccionados, setSeleccionados] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [reFarmacos, setReFarmacos] = useState(farmacos);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [lote, setLote] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const dt2 = useRef(null);

    const handleSubmit = async () => {
        setLoadingDialog(true);
        if (!seleccionados.length) {
            toast.current.show({ severity: "error", summary: "Error", detail: `Debe seleccionar Farmacos o Insumos medicos`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
        let _seleccionados = [];

        let info = {
            usuario_id: usuario.data.sub,
            recinto_id: usuario.data.recinto_id,
        };
        seleccionados.forEach((data) => {
            _seleccionados = [..._seleccionados, { farmaco_id: data.farmaco_id, cantidad: data.seleccionado }];
        });

        let json = JSON.stringify({ ...info, farmacos: _seleccionados });
        let params = "json=" + json;

        let res = await solicitudesRecintosService.solicitud(params);

        if (res?.status) {
            if (res.status === "success") {
                toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                setLoadingDialog(false);
                window.open(`${process.env.REACT_APP_API_URL}/api/solicitudRecintoPDF/${res.data.cod_solicitud}`);
                setTimeout(() => {
                    setRedirect(true);
                }, 1000);
            } else {
                let errors = { ...res.errors };
                for (const property in errors) {
                    toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                }
                setLoadingDialog(false);
                return;
            }
        } else {
            toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
            setLoadingDialog(false);
            return;
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" tooltip="Seleccionar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => viewCantidad(rowData)} />
            </div>
        );
    };

    const actionDeleteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-minus" tooltip="Quitar Fármaco o Insumo Médico" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSeleccion(rowData)} />
            </div>
        );
    };

    const seleccionarFarmaco = (rowData) => {
        let _farmaco = { ...rowData, seleccionado: cantidad };
        let _reFarmacos = reFarmacos.filter((data) => data.id !== _farmaco.id);
        setReFarmacos(_reFarmacos);
        setSeleccionados([...seleccionados, _farmaco]);
        hideDialog();
        setCantidad(0);
    };

    useEffect(() => {
        setReFarmacos(farmacos);
    }, [farmacos]);

    if (redirect) {
        return <Redirect to="/" />;
    }
    const deleteSeleccion = (rowData) => {
        let _farmaco = { ...rowData, seleccionado: 0 };
        let _selecionados = seleccionados.filter((data) => data.id !== rowData.id);
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote quitado correctamente`, life: 3000 });
        setSeleccionados(_selecionados);
        setReFarmacos([...reFarmacos, _farmaco]);
    };

    const openNew = () => {
        setProductDialog(true);
    };

    const hideDialog = () => {
        setProductDialog(false);
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        setCantidad(val);
    };

    const viewCantidad = (rowData) => {
        setCantidadDialog(true);
        setLote(rowData);
    }

    const saveSeleccion = () => {
        setSubmitted(true);
        if (cantidad < 1){
            return;
        }
        toast.current.show({ severity: "success", summary: "Éxito", detail: `Lote agregado correctamente`, life: 3000 });
        seleccionarFarmaco(lote)
        hideCantidad();
        setSubmitted(false);
    };

    const hideCantidad = () => {
        setCantidadDialog(false);
        setSubmitted(false);
    };

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideCantidad} />
            {cantidad > 0 && <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveSeleccion} />}
        </>
    );

    const header2 = (
        <div className="table-header">
            <h5>Fármacos e Insumos Médicos seleccionados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let validacion = !seleccionados.length ? true : false;

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Solicitud Recinto</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <h5>Registrar Solicitud</h5>
                                    <div className="p-field">
                                        <label htmlFor="nombre">Nombre</label>
                                        <InputText id="nombre" value={`${usuario.data.nombre} ${usuario.data.apellido_paterno} ${usuario.data.apellido_materno}`} disabled type="text" />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="numero">Recinto</label>
                                        <InputText id="numero" value={usuario.data.recinto} disabled type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid p-justify-between">
                            <Button tooltip="Agregar Fármaco o Insumo Médico" onClick={openNew}>Agregar Fármaco o insumo Médico</Button>
                            <Button tooltip="Registrar Solicitud" className="p-button p-button-success p-mr-2" disabled={validacion} onClick={handleSubmit}>
                                Registrar
                            </Button>
                        </div>
                    </div>

                    <br />

                    <DialogProcesando visible={loadingDialog} />

                    <DataTable
                        ref={dt2}
                        value={seleccionados}
                        dataKey="id2"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Seleccionados"
                        globalFilter={globalFilter}
                        header={header2}
                    >
                        <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                        <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                        <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable></Column>
                        <Column field="seleccionado" header="Cantidad seleccionada" sortable></Column>
                        <Column header="Eliminar" body={actionDeleteBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "1000px" }} header="Seleccionar Fármaco O Insumo Médico" modal className="p-fluid" onHide={hideDialog}>
                        <DataTable
                            ref={dt}
                            value={reFarmacos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e Insumos Médicos"
                            globalFilter={globalFilter2}
                            header={header}
                        >
                            <Column field="farmaco.nombre" header="Nombre" sortable></Column>
                            <Column field="farmaco.dosificacion" header="Dosificación" sortable></Column>
                            <Column field="farmaco.forma_farmaceutica.nombre" header="Forma Farmaceutica" sortable></Column>
                            <Column header="Seleccionar" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>
                    <Dialog visible={cantidadDialog} style={{ width: "700px" }} header="Cantidad a seleccionar" modal className="p-fluid" footer={cantidadDialogFooter} onHide={hideCantidad}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" value={cantidad} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && !cantidad })} />
                            {submitted && !cantidad && <small className="p-invalid">Cantidad es requerida.</small>}
                        </div>
                    </Dialog>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

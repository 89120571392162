import axios from "axios";

export class UsuarioService {
    async getUsuarios() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/usuarios`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async usuario(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/usuario`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async cambiarContraseña(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/cambiarContraseña`, data)
            .then((response) => {

                return response.data;
            })
            .catch((error) => {
                
                return error;
            });
    }

    async updateUsuario(data) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/usuario/${data.id}`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}

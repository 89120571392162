import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ConsumoService } from "../service/ConsumoService";

export const DespachosConFecha = () => {
    const { id } = useParams();
    const [data, setData] = useState({ inicio: new Date(), fin: new Date(), recinto_id: id });
    const [lotes, setLotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    let es = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Limpiar",
        dateFormat: "dd/mm/yy",
        weekHeader: "Sm",
    };

    useEffect(() => {
        setLoading(true);
        const consumoServices = new ConsumoService();
        consumoServices.despachosRecintos(data).then((data2) => {
            setLoading(false);
            let _res = [];
            data2.lotes.forEach( dat => {
                _res = [..._res, ...dat];
            })
            setLotes(_res);
        });
    }, [data]);

    const fechaBodyTemplate = (rowData) => {
        let _fecha = new Date(rowData.fecha_vencimiento);
        return <span>{_fecha.toLocaleDateString()}</span>;
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Lotes Despachados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-col-12 p-lg-12">
            <div>
                <div className="card">
                    <div className="p-fluid p-grid p-justify-between">
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="basic">Desde</label>
                            <Calendar id="basic" locale={es} value={data.inicio} dateFormat="dd/mm/yy" onChange={(e) => setData({ ...data, inicio: e.value })} />
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                            <label htmlFor="basic">Hasta</label>
                            <Calendar id="basic" locale={es} value={data.fin} dateFormat="dd/mm/yy" onChange={(e) => setData({ ...data, fin: e.value })} />
                        </div>
                    </div>

                    <DataTable
                        ref={dt}
                        value={lotes}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e insumos Médicos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="cod_lote" header="Código"></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="dosificacion" header="Dosificación" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha Vencimietno" body={fechaBodyTemplate} sortable></Column>
                        <Column field="forma_farmaceutica" header="Forma Farmacéutica" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

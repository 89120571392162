import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { GruposService } from "../service/GruposService";
import { Dropdown } from "primereact/dropdown";
import { FarmacosService } from "../service/FarmacosService";
import { FormasFarmaceuitcasService } from "../service/FormasFarmaceuticasService";
import { BodegasService } from "../service/BodegasService";
import { ProgressSpinner } from "primereact/progressspinner";
import { RadioButton } from "primereact/radiobutton";
import { Helmet } from "react-helmet";
import { DialogProcesando } from "../components/DialogProcesando";

const farmacosService = new FarmacosService();

export const Farmacos = ({ farmacos, setFarmacos, loading }) => {
    let farmacoVacio = {
        id: null,
        nombre: "",
        observacion: "",
        dosificacion: "",
        stock_critico: "",
        bodega_id: null,
        grupo_id: null,
        forma_farmaceutica_id: null,
        desactivado: 0,
    };

    const [productDialog, setProductDialog] = useState(false);
    const [farmaco, setfarmaco] = useState(farmacoVacio);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [grupos, setGrupos] = useState([]);
    const [grupo, setGrupo] = useState(null);
    const [formasFarmaceuticas, setFormasFarmaceuticas] = useState([]);
    const [formaFarmaceutica, setFormaFarmaceutica] = useState(null);
    const [bodegas, setBodegas] = useState([]);
    const [bodega, setBodega] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const gruposService = new GruposService();
        gruposService.getGrupos().then((data) => setGrupos(data));

        const formasFarmaceuticasService = new FormasFarmaceuitcasService();
        formasFarmaceuticasService.getFormasFarmaceuticas().then((data) => {
            setFormasFarmaceuticas(data);
        });

        const bodegaService = new BodegasService();
        bodegaService.getBodegas().then((data) => setBodegas(data));
    }, []);

    const openNew = () => {
        setfarmaco(farmacoVacio);
        setSubmitted(false);
        setProductDialog(true);
    };
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const saveProduct = async () => {
        setSubmitted(true);
        setLoadingDialog(true);

        if (farmaco.nombre.trim() && (grupo || farmaco.grupo_id) && (formaFarmaceutica || farmaco.forma_farmaceutica_id) && (bodega || farmaco.bodega_id) && farmaco.dosificacion.trim() && farmaco.stock_critico) {
            let _farmacos = [...farmacos];

            let _farmaco = { ...farmaco, bodega_id: bodega ? bodega : farmaco.bodega_id, grupo_id: grupo ? grupo : farmaco.grupo_id, forma_farmaceutica_id: formaFarmaceutica ? formaFarmaceutica : farmaco.forma_farmaceutica_id };
            let _grupo = grupos.find((grupo) => grupo.id === _farmaco.grupo_id);
            let _formaFarmaceutica = formasFarmaceuticas.find((forma) => forma.id === _farmaco.forma_farmaceutica_id);
            let _bodega = grupos.find((bodega) => bodega.id === _farmaco.bodega_id);
            if (farmaco.id) {
                const index = findIndexById(farmaco.id);

                let res = await farmacosService.updateFarmaco(_farmaco);
                _farmaco = { ...farmaco, bodega: _bodega, grupo: _grupo, forma_farmaceutica: _formaFarmaceutica };

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _farmacos[index] = _farmaco;
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        setLoadingDialog(false);
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
            } else {
                let res = await farmacosService.farmaco(_farmaco);

                if (res?.status) {
                    if (res.status === "success") {
                        toast.current.show({ severity: "success", summary: "Éxito", detail: `${res.message}`, life: 3000 });
                        _farmaco = { ..._farmaco, id: res.data.id, grupo: _grupo, forma_farmaceutica: _formaFarmaceutica };
                        _farmacos.push(_farmaco);
                    } else {
                        toast.current.show({ severity: "error", summary: "Fallo", detail: `${res.message}`, life: 3000 });
                        let errors = { ...res.errors };
                        for (const property in errors) {
                            toast.current.show({ severity: "error", summary: "Fallo", detail: errors[property], life: 3000 });
                        }
                        setLoadingDialog(false);
                        return;
                    }
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `Problemas de servidor`, life: 3000 });
                    setLoadingDialog(false);
                    return;
                }
            }
            
            setLoadingDialog(false);
            setFarmacos(_farmacos);
            setProductDialog(false);
            setfarmaco(farmacoVacio);
            setBodega(null);
            setGrupo(null);
            setFormaFarmaceutica(null);
            setSubmitted(false);
        }
    };

    const editProduct = (farmaco) => {
        setfarmaco({ ...farmaco });
        setProductDialog(true);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < farmacos.length; i++) {
            if (farmacos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _farmaco = { ...farmaco };
        _farmaco[`${name}`] = val;

        setfarmaco(_farmaco);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _farmaco = { ...farmaco };
        _farmaco[`${name}`] = val;

        setfarmaco(_farmaco);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" tooltip="Nuevo Fármaco o Insumo Médico" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.id}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        let status;
        if (rowData.cantidad >= rowData.stock_critico) {
            status = {
                status: "instock",
                message: rowData.cantidad,
            };
        } else {
            status = {
                status: "outofstock",
                message: rowData.cantidad,
            };
        }
        return (
            <>
                <span className="p-column-title">Stock</span>
                <span className={`product-badge status-${status.status}`}>{status.message}</span>
            </>
        );
    };

    const dosificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dosificación</span>
                {rowData.dosificacion}
            </>
        );
    };

    const grupoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Grupo</span>
                {rowData.grupo.nombre}
            </>
        );
    };

    const formaFarmeceuticaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Forma Farmaceutica</span>
                {rowData.forma_farmaceutica.nombre}
            </>
        );
    };

    const onRecintoChange = (e) => {
        let _farmaco = { ...farmaco };
        _farmaco["desactivado"] = e.value;
        setfarmaco(_farmaco);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Editar Fármaco o Insumo Médico" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Fármacos e Insumos Médicos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    let valido = farmaco.nombre.length <= 100 && farmaco.observacion.length <= 100 && farmaco.dosificacion.length <= 100 ? false : true;

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" disabled={valido} className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <Helmet>
                <title>MATNELO - Fármaco e Insumos Médicos</title>
            </Helmet>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
                    {loading && (
                        <>
                            <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        </>
                    )}

                    <DataTable
                        ref={dt}
                        value={farmacos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Fármacos e insumos Médicos"
                        globalFilter={globalFilter}
                        header={header}
                        loading={loading}
                    >
                        <Column field="codigo" header="Código" body={idBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="dosificacion" header="Dosificación" sortable body={dosificacionBodyTemplate}></Column>
                        <Column field="grupo.nombre" header="Grupo" sortable body={grupoBodyTemplate}></Column>
                        <Column field="forma_farmaceutica.nombre" header="Forma Farmacéutica" sortable body={formaFarmeceuticaBodyTemplate}></Column>
                        <Column header="Stock" body={statusBodyTemplate}></Column>

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingDialog} />

                    <Dialog visible={productDialog} style={{ width: "600px" }} header="Detalles Fármaco O Insumo Médico" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={farmaco.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !farmaco.nombre })} />
                            {submitted && !farmaco.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                            {farmaco.nombre.length > 100 && <small className="p-invalid">El nombre del fármaco o insumo médico no puede exceder los 100 caracteres.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="state">Grupo</label>
                            <Dropdown id="state" value={grupo} onChange={(e) => setGrupo(e.value)} options={grupos} optionValue="id" optionLabel="nombre" placeholder="Seleccione un grupo" className={classNames({ "p-invalid": submitted && !farmaco.grupo_id })}></Dropdown>
                            {submitted && !grupo && !farmaco.grupo_id && <small className="p-invalid">Grupo requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="formas">Forma Farmaceutica</label>
                            <Dropdown
                                id="formas"
                                value={formaFarmaceutica}
                                onChange={(e) => setFormaFarmaceutica(e.value)}
                                options={formasFarmaceuticas}
                                optionValue="id"
                                optionLabel="nombre"
                                placeholder="Seleccione una forma farmaceutica"
                                className={classNames({ "p-invalid": submitted && !farmaco.forma_farmaceutica_id })}
                            ></Dropdown>
                            {submitted && !formaFarmaceutica && !farmaco.forma_farmaceutica_id && <small className="p-invalid">Forma Farmaceutica requerida.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="bodega">Bodega</label>
                            <Dropdown id="bodega" value={bodega} onChange={(e) => setBodega(e.value)} options={bodegas} optionValue="id" optionLabel="nombre" placeholder="Seleccione una bodega" className={classNames({ "p-invalid": submitted && !farmaco.bodega_id })}></Dropdown>
                            {submitted && !bodega && !farmaco.bodega_id && <small className="p-invalid">Bodega requerida.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="observacion">Observacion</label>
                            <InputTextarea id="observacion" value={farmaco.observacion} onChange={(e) => onInputChange(e, "observacion")} required rows={3} cols={20} />
                            {farmaco.observacion.length > 100 && <small className="p-invalid">La observacion del farmaco o isnumo medico no puede exceder los 100 caracteres.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="dosificacion">Dosificación</label>
                            <InputText id="dosificacion" value={farmaco.dosificacion} onChange={(e) => onInputChange(e, "dosificacion")} required autoFocus className={classNames({ "p-invalid": submitted && !farmaco.dosificacion })} />
                            {submitted && !farmaco.dosificacion && <small className="p-invalid">Dosificación es requerido.</small>}
                            {farmaco.dosificacion.length > 100 && <small className="p-invalid">La dosificación del farmaco o isnumo medico no puede exceder los 100 caracteres.</small>}
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="stock">Stock Critico</label>
                                <InputNumber id="stock" value={farmaco.stock_critico} onChange={(e) => onInputNumberChange(e, "stock_critico")} integeronly />
                                {submitted && !farmaco.stock_critico && <small className="p-invalid">Stock critico es requerido.</small>}
                            </div>
                        </div>

                        <div className="p-field">
                            <label className="p-mb-3">Estado Notificacion</label>
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category1" name="recinto_id" value={0} onChange={onRecintoChange} checked={0 === farmaco.desactivado} />
                                    <label htmlFor="category1">Activo</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category1" name="recinto_id" value={1} onChange={onRecintoChange} checked={1 === farmaco.desactivado} />
                                    <label htmlFor="category1">Desactivado</label>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";

export class DevolucionesIntService {
   
    async farmacosLotesPrestamo(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getFarmacosDevolucionInt/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async devolucionInt(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/devolucion_int`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getDevolucionesInt() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDevolucionesInt`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getDevolucionInt(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getDevolucionInt/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

}
import axios from "axios";

export class PrestamosExtService {

    async getPrestamosExt() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/prestamos_ext`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getPrestamoExt(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/get_prestamo_ext/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getLotesDisponiblesExt() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/getLotesDisponiblesExt`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async prestamo_ext(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/prestamo_ext`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

}
import axios from "axios";

export class RecintosFarmacosService {
    async getRecintosFarmacos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recinto_farmaco/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async getRecintosFarmacosActivos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recinto_farmaco_activos/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async recintoFarmaco(farmaco) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/recinto_farmaco`, farmaco)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async updateRecintoFarmaco(farmaco) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/recinto_farmaco/${farmaco.id}`, farmaco)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async lotesDisponiblesRecintoVencimiento(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lotesDisponiblesRecintoVencimiento/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

    async lotesDisponiblesRecintoVencidos(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lotesDisponiblesRecintoVencidos/${id}`)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return error;
            });
    }

}